// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Category, ListCategoriesFilter } from '../model/models';
import { ListCategoriesActionTypes, ListCategoriesTypes } from './actions';

export interface CategoriesState {
  categories: Category[];
  currentPage?: number;
  error?: Error;
  filter?: ListCategoriesFilter;
  isSuccess?: boolean;
  loading: boolean;
  total: number;
  searchString?: string;
  size?: number;
}

export const DEFAULT_CATEGORIES_SIZE = 9000;

export const initalState: CategoriesState = {
  total: 0,
  loading: false,
  categories: [],
  size: DEFAULT_CATEGORIES_SIZE,
};

export default function categoriesReducer(
  state = initalState,
  action: ListCategoriesTypes,
) {
  switch (action.type) {
    case ListCategoriesActionTypes.LIST_CATEGORIES_REQUEST:
      const { currentPage, searchString, filter } = action;

      return update(state, {
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
      });

    case ListCategoriesActionTypes.LIST_CATEGORIES_SUCCESS:
      const { total, categories } = action;

      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        total: { $set: total },
        categories: { $set: categories },
      });

    case ListCategoriesActionTypes.LIST_CATEGORIES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case ListCategoriesActionTypes.RESET_LIST_CATEGORIES:
      return state;

    default:
      return state;
  }
}
