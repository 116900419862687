/* External dependencies */
import * as AWSCognito from 'amazon-cognito-identity-js';

/* Local dependencies */
import { SessionUser } from '@entities/authentication/redux/actions';
import CustomCognitoUserSession from './cognitoUserSession';

export const COGNITO_USER_POOL_DATA = {
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID!,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID!,
};

interface Token {
  payload: SessionUser;
  jwtToken: string;
}

interface RefreshToken {
  token: string;
}
export interface Session {
  accessToken?: Token;
  email: string;
  email_verified: Boolean;
  family_name: string;
  given_name: string;
  idToken?: Token;
  newPasswordRequired?: Boolean;
  phone_number: Number;
  phone_number_verified: Boolean;
  refreshToken?: RefreshToken;
}

export function getSessionUser(idToken: Token | AWSCognito.CognitoIdToken) {
  return idToken.payload;
}

export async function cognitoSignOut() {
  return new Promise((resolve, reject) => {
    const cognitoUserPool = new AWSCognito.CognitoUserPool(
      COGNITO_USER_POOL_DATA,
    );
    const cognitoUser = cognitoUserPool.getCurrentUser();

    cognitoUser!.getSession(function (err, result) {
      if (err) {
        return reject(err);
      }

      if (!result) {
        return resolve(null);
      }

      cognitoUser!.signOut(() => resolve(null));
    });
  });
}

export async function getSession(): Promise<CustomCognitoUserSession> {
  return new Promise((resolve, reject) => {
    const cognitoUserPool = new AWSCognito.CognitoUserPool(
      COGNITO_USER_POOL_DATA,
    );
    const cognitoUser = cognitoUserPool.getCurrentUser();

    const session = cognitoUser!.getSignInUserSession();

    cognitoUser!.getSession(function (err, session) {
      if (err) {
        return reject(err);
      }

      resolve(session);
    });
  });
}
