// Local dependencies
import { ActionType, UserActionStatus } from '../../types';
import { ListUserActionsFilter, UserAction } from './models';

export enum ListUserActionsActionTypes {
  LIST_USER_ACTIONS_REQUEST = 'LIST_USER_ACTIONS_REQUEST',
  LIST_USER_ACTIONS_ERROR = 'LIST_USER_ACTIONS_ERROR',
  LIST_USER_ACTIONS_SUCCESS = 'LIST_USER_ACTIONS_SUCCESS',
  RESET_LIST_USER_ACTIONS = 'RESET_LIST_USER_ACTIONS',
}

export type ListUserActionsTypes =
  | ListUserActions
  | ListUserActionsSucceeded
  | ListUserActionsError
  | ResetListUserActions;

export interface ListUserActions {
  currentPage: number;
  filter?: ListUserActionsFilter;
  searchString: string;
  type: ListUserActionsActionTypes.LIST_USER_ACTIONS_REQUEST;
}

export interface ListUserActionsSucceeded {
  userActions: UserAction[];
  total: number;
  type: ListUserActionsActionTypes.LIST_USER_ACTIONS_SUCCESS;
}

export interface ListUserActionsError {
  error: Error;
  type: ListUserActionsActionTypes.LIST_USER_ACTIONS_ERROR;
}

export interface ResetListUserActions {
  type: ListUserActionsActionTypes.RESET_LIST_USER_ACTIONS;
}

export interface ListUserActionsFilter {
  locale?: String;
  actionType: ActionType;
  status?: UserActionStatus;
}

export const DEFAULT_LIST_USER_ACTIONS_FILTER: ListUserActionsFilter = {
  actionType: ActionType.PURCHASE,
  status: UserActionStatus.IN_REVIEW,
};

export function listUserActions(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListUserActionsFilter,
): ListUserActions {
  return {
    currentPage,
    filter,
    searchString,
    type: ListUserActionsActionTypes.LIST_USER_ACTIONS_REQUEST,
  };
}

export function listUserActionsSucceeded(
  total: number,
  userActions: UserAction[],
): ListUserActionsSucceeded {
  return {
    userActions,
    total,
    type: ListUserActionsActionTypes.LIST_USER_ACTIONS_SUCCESS,
  };
}

export function listUserActionsError(error: Error): ListUserActionsError {
  return {
    error,
    type: ListUserActionsActionTypes.LIST_USER_ACTIONS_ERROR,
  };
}

export function resetListUserActions(): ResetListUserActions {
  return {
    type: ListUserActionsActionTypes.RESET_LIST_USER_ACTIONS,
  };
}
