// External depedencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '@app/clients/medcheck';
import { Prize } from '@entities/prizes/lib/types';
import {
  CreatePrizeAction,
  CreatePrizeActionTypes,
  CreatePrizeRequest,
  createPrizeFailed,
  createPrizeSucceeded,
} from './actions';
import { createPrizeMutation } from './mutation';

export default function createPrizeEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreatePrizeAction) =>
        action.type === CreatePrizeActionTypes.CREATE_PRIZE_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: CreatePrizeRequest) =>
      createPrize(action)
        .then((prize) => createPrizeSucceeded(prize))
        .catch((error) => createPrizeFailed(error)),
    ),
  );
}

export async function createPrize({
  prize,
}: CreatePrizeRequest): Promise<Prize> {
  const graphQLClient = await getClient();

  const {
    data: { createPrize },
  } = await graphQLClient.mutate({
    mutation: createPrizeMutation,
    variables: {
      input: prize,
    },
  });

  return createPrize as Prize;
}
