/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { CreateCourseItemInput } from '../../types';
import { CreateCourseItemActionTypes, CreateCourseItemAction } from './actions';
import { validateCreateCourseItem } from './validateCreateCourseItem';

export interface CreateCourseItemState {
  courseItem: CreateCourseItemInput;
  descriptionError?: Error;
  error?: Error;
  isFormChanged?: boolean;
  isSuccess: boolean;
  loading: boolean;
  itemError?: Error;
  nameError?: Error;
  parentIdError?: Error;
  titleError?: Error;
  imageRuError?: Error;
  imageKyError?: Error;
  imageEnError?: Error;
  nameRuError?: Error;
  nameKyError?: Error;
  nameEnError?: Error;
  videoUrlError?: Error;
}

export const CreateCourseItemInitialState: CreateCourseItemState = {
  courseItem: {
    item: {
      previewImage: {
        en: '',
        ru: '',
        ky: '',
      },
      url: '',
      title: {
        en: '',
        ru: '',
        ky: '',
      },
      description: {
        en: '',
        ru: '',
        ky: '',
      },
      duration: null,
    },
    title: {
      en: '',
      ru: '',
      ky: '',
    },
    name: {
      en: '',
      ru: '',
      ky: '',
    },
    description: {
      en: '',
      ru: '',
      ky: '',
    },
    parentId: '',
  },
  isFormChanged: false,
  isSuccess: false,
  loading: false,
};

export default function createCourseItemReducer(
  state = CreateCourseItemInitialState,
  action: CreateCourseItemAction,
) {
  switch (action.type) {
    case CreateCourseItemActionTypes.CREATE_COURSE_ITEM_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case CreateCourseItemActionTypes.CREATE_COURSE_ITEM_SUCCESS:
      return update(state, {
        courseItem: { $set: action.courseItem },
        isSuccess: { $set: true },
        loading: { $set: false },
      });

    case CreateCourseItemActionTypes.CREATE_COURSE_ITEM_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case CreateCourseItemActionTypes.CREATE_COURSE_ITEM_ADD_VALUE:
      return validateCreateCourseItem(state, action);

    case CreateCourseItemActionTypes.CREATE_COURSE_ITEM_RESET:
      return CreateCourseItemInitialState;

    case CreateCourseItemActionTypes.CREATE_COURSE_ITEM_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
