/* External dependencies */
import gql from 'graphql-tag';

export const getArticleQuery = gql`
  query getArticle($input: GetArticleInput!) {
    getArticle(input: $input) {
      id
      articleType
      category
      contents {
        type
        data
      }
      heroMedia {
        imageUrl
        fullVideoUrl
      }
      readTimeMinute
      synopsis
      title
    }
  }
`;
