// External dependencies
import OneSignal from 'react-onesignal';

// Local dependencies
import { setAnonymousClient } from './src/app/clients/medcheck.ts';
import wrapWithProvider from './src/app/redux/wrap-with-provider';
import {
  getItemFromLocalStorage,
  setLocalStorage,
} from './src/shared/utils/helpers';

export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () => {
  const localStorateItem = 'one-signal-connection';
  const connectionStatus = getItemFromLocalStorage(localStorateItem);

  setAnonymousClient();

  if (!connectionStatus) {
    setLocalStorage(localStorateItem, 'connected');

    OneSignal.init({ appId: process.env.GATSBY_ONESIGNAL_APP_ID }).then(() => {
      OneSignal.showSlidedownPrompt().then(() => {});
    });
  }
};
