// Local dependencies
import { ProAccountEntry } from '../../lib/types';

export enum GetUserActionTypes {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR',
  GET_USER_RESET = 'GET_USER_RESET',
}

export interface GetUserRequest {
  type: GetUserActionTypes.GET_USER_REQUEST;
  id: string;
  __typename: string;
}

export interface GetUserSuccess {
  type: GetUserActionTypes.GET_USER_SUCCESS;
  user: ProAccountEntry;
}

export interface GetUserError {
  type: GetUserActionTypes.GET_USER_ERROR;
  error: Error;
}

export interface GetUserReset {
  type: GetUserActionTypes.GET_USER_RESET;
}

export type GetUserAction =
  | GetUserRequest
  | GetUserSuccess
  | GetUserError
  | GetUserReset;

export function getUser(id: string, __typename: string): GetUserRequest {
  return {
    type: GetUserActionTypes.GET_USER_REQUEST,
    id,
    __typename,
  };
}

export function getUserSucceeded(user: ProAccountEntry): GetUserSuccess {
  return {
    type: GetUserActionTypes.GET_USER_SUCCESS,
    user,
  };
}

export function getUserFailure(error: Error): GetUserError {
  return {
    type: GetUserActionTypes.GET_USER_ERROR,
    error,
  };
}

export function getUserReset(): GetUserReset {
  return {
    type: GetUserActionTypes.GET_USER_RESET,
  };
}
