// External dependencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '@app/clients/medcheck';
import {
  ListCategories,
  ListCategoriesActionTypes,
  listCategoriesError,
  listCategoriesSucceeded,
  ListCategoriesSucceeded,
  ListCategoriesTypes,
} from './actions';
import { listCategoriesQuery } from './queries';

export default function listCategoriesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListCategoriesTypes) =>
        action.type === ListCategoriesActionTypes.LIST_CATEGORIES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListCategories) =>
      listCategories(action, state$?.value?.listCategories?.size).catch(
        (error) => listCategoriesError(error),
      ),
    ),
  );
}

export async function listCategories(
  { currentPage, searchString, filter }: ListCategories,
  size: number,
): Promise<ListCategoriesSucceeded> {
  const graphQLClient = await getClient();

  const {
    data: {
      listCategories: { categories, total },
    },
  } = await graphQLClient.query({
    query: listCategoriesQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size: size * currentPage,
        filter,
      },
    },
  });
  return listCategoriesSucceeded(total, categories);
}
