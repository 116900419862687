/* External dependencies */
import gql from 'graphql-tag';

export const createCourseItemMutation = gql`
  mutation createCourseItem($input: CreateCourseItemInput) {
    createCourseItem(input: $input) {
      id
      currentWatchTime
      description {
        en
        ru
        ky
      }
      isLiked
      isWatched
      item {
        previewImage {
          en
          ru
          ky
        }
        url
        title {
          en
          ru
          ky
        }
        description {
          en
          ru
          ky
        }
        duration
      }
      likesCount
      name {
        en
        ru
        ky
      }
      parentId
      postDate
      reviews {
        id
        parentId
        message
        rating
        likeCount
        likeState
        repliesCount
        postDate
        updatedDate
      }
      reviewsCount
      status
      title {
        en
        ru
        ky
      }
      __typename
    }
  }
`;
