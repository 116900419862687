// External dependencies
import gql from 'graphql-tag';

export const createPrizeMutation = gql`
  mutation createPrize($input: CreatePrizeInput!) {
    createPrize(input: $input) {
      id
      name
      avatar {
        url
      }
      status
      requiredPoints
      postDate
      orderNumber
    }
  }
`;
