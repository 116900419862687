// Local dependencies
import { Course, CourseStatus, ListCoursesFilter } from '../../types';

export enum ListCoursesActionTypes {
  LIST_COURSES_REQUEST = 'LIST_COURSES_REQUEST',
  LIST_COURSES_SUCCESS = 'LIST_COURSES_SUCCESS',
  LIST_COURSES_ERROR = 'LIST_COURSES_ERROR',
  LIST_COURSES_RESET = 'LIST_COURSES_RESET',
  LIST_COURSES_CHANGE_CURRENT_FILTER = 'LIST_COURSES_CHANGE_CURRENT_FILTER',
  LIST_COURSES_CHANGE_SEARCH_STRING = 'LIST_COURSES_CHANGE_SEARCH_STRING',
}

export type ListCoursesAction =
  | ListCoursesRequest
  | ListCoursesSuccess
  | ListCoursesError
  | ListCoursesReset
  | ListCoursesChangeCurrentFilter
  | ListCoursesChangeSearchString;

export interface ListCoursesRequest {
  type: ListCoursesActionTypes.LIST_COURSES_REQUEST;
  searchString: string;
  filter: ListCoursesFilter;
}

export interface ListCoursesSuccess {
  type: ListCoursesActionTypes.LIST_COURSES_SUCCESS;
  total: number;
  courses: Course[];
}

export interface ListCoursesError {
  type: ListCoursesActionTypes.LIST_COURSES_ERROR;
  error: Error;
}

export interface ListCoursesReset {
  type: ListCoursesActionTypes.LIST_COURSES_RESET;
}

export interface ListCoursesChangeCurrentFilter {
  type: ListCoursesActionTypes.LIST_COURSES_CHANGE_CURRENT_FILTER;
  filter: CourseStatus;
}

export interface ListCoursesChangeSearchString {
  type: ListCoursesActionTypes.LIST_COURSES_CHANGE_SEARCH_STRING;
  searchString: string;
}

export const DEFAULT_LIST_COURSES_FILTER = {
  status: CourseStatus.ENABLED,
};

export function listCourses(
  searchString: string = '',
  filter: ListCoursesFilter = DEFAULT_LIST_COURSES_FILTER,
): ListCoursesRequest {
  return {
    type: ListCoursesActionTypes.LIST_COURSES_REQUEST,
    searchString,
    filter,
  };
}

export function listCoursesSucceeded(
  total: number,
  courses: Course[],
): ListCoursesSuccess {
  return {
    type: ListCoursesActionTypes.LIST_COURSES_SUCCESS,
    total,
    courses,
  };
}

export function listCoursesFailed(error: Error): ListCoursesError {
  return {
    type: ListCoursesActionTypes.LIST_COURSES_ERROR,
    error,
  };
}

export function listCoursesReset() {
  return {
    type: ListCoursesActionTypes.LIST_COURSES_RESET,
  };
}

export function listCoursesChangeCurrentFilter(
  filter: CourseStatus,
): ListCoursesChangeCurrentFilter {
  return {
    type: ListCoursesActionTypes.LIST_COURSES_CHANGE_CURRENT_FILTER,
    filter,
  };
}

export function listCoursesChangeSearchString(
  searchString: string,
): ListCoursesChangeSearchString {
  return {
    type: ListCoursesActionTypes.LIST_COURSES_CHANGE_SEARCH_STRING,
    searchString,
  };
}
