/* Local dependencies */
import {
  CourseItem,
  CreateCourseItemInput,
  CreateCourseItemInputFields,
} from '../../types';

export enum CreateCourseItemActionTypes {
  CREATE_COURSE_ITEM_REQUEST = 'CREATE_COURSE_ITEM_REQUEST',
  CREATE_COURSE_ITEM_SUCCESS = 'CREATE_COURSE_ITEM_SUCCESS',
  CREATE_COURSE_ITEM_ERROR = 'CREATE_COURSE_ITEM_ERROR',
  CREATE_COURSE_ITEM_RESET = 'CREATE_COURSE_ITEM_RESET',
  CREATE_COURSE_ITEM_RESET_ERROR = 'CREATE_COURSE_ITEM_RESET_ERROR',
  CREATE_COURSE_ITEM_ADD_VALUE = 'CREATE_COURSE_ITEM_ADD_VALUE',
}

export type CreateCourseItemAction =
  | CreateCourseItemRequest
  | CreateCourseItemSuccess
  | CreateCourseItemError
  | CreateCourseItemReset
  | CreateCourseItemResetError
  | CreateCourseItemAddValue;

export interface CreateCourseItemRequest {
  type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_REQUEST;
  courseItemInput: CreateCourseItemInput;
}

export interface CreateCourseItemSuccess {
  type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_SUCCESS;
  courseItem: CourseItem;
}

export interface CreateCourseItemError {
  type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_ERROR;
  error: Error;
}

export interface CreateCourseItemReset {
  type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_RESET;
}

export interface CreateCourseItemResetError {
  type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_RESET_ERROR;
}

export interface CreateCourseItemAddValue {
  type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_ADD_VALUE;
  updates: CreateCourseItemInputFields;
}

export function createCourseItemRequest(
  courseItemInput: CreateCourseItemInput,
): CreateCourseItemRequest {
  return {
    type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_REQUEST,
    courseItemInput,
  };
}

export function createCourseItemSucceeded(
  courseItem: CourseItem,
): CreateCourseItemSuccess {
  return {
    type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_SUCCESS,
    courseItem,
  };
}

export function createCourseItemFailed(error: Error): CreateCourseItemError {
  return {
    type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_ERROR,
    error,
  };
}

export function createCourseItemReset(): CreateCourseItemReset {
  return {
    type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_RESET,
  };
}

export function createCourseItemResetError(): CreateCourseItemResetError {
  return {
    type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_RESET_ERROR,
  };
}

export function createCourseItemAddValue(
  updates: CreateCourseItemInputFields,
): CreateCourseItemAddValue {
  return {
    type: CreateCourseItemActionTypes.CREATE_COURSE_ITEM_ADD_VALUE,
    updates,
  };
}
