// Local dependencies
import { Article, CreateArticleInput } from '../../types';

export enum CreateArticleTypes {
  CREATE_ARTILCE_REQUEST = 'CREATE_ARTICLE_REQUEST',
  CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS',
  CREATE_ARTICLE_ERROR = 'CREATE_ARTICLE_ERROR',
  CREATE_ARTICLE_RESET_ERROR = 'CREATE_ARTICLE_RESET_ERROR',
  CREATE_ARTICLE_UPDATE_FIELDS = 'CREATE_ARTICLE_UPDATE_FIELDS',
  CREATE_ARTICLE_RESET = 'CREATE_ARTICLE_RESET',
  OPEN_INFORMATION_POPUP = 'OPEN_INFORMATION_POPUP',
}

export type CreateArticleActionTypes =
  | CreateArticleRequest
  | CreateArticleSuccess
  | CreateArticleError
  | CreateArticleResetError
  | CreateArticleUpdateFields
  | CreateArticleReset
  | InformationPopup;

export interface CreateArticleRequest {
  type: CreateArticleTypes.CREATE_ARTILCE_REQUEST;
  article: CreateArticleInput;
}

export interface CreateArticleReset {
  type: CreateArticleTypes.CREATE_ARTICLE_RESET;
}

export interface CreateArticleSuccess {
  type: CreateArticleTypes.CREATE_ARTICLE_SUCCESS;
  article: Article;
}

export interface CreateArticleError {
  type: CreateArticleTypes.CREATE_ARTICLE_ERROR;
  error: Error;
}

export interface CreateArticleResetError {
  type: CreateArticleTypes.CREATE_ARTICLE_RESET_ERROR;
}

export interface InformationPopup {
  type: CreateArticleTypes.OPEN_INFORMATION_POPUP;
  value: boolean;
}

export interface CreateArticleUpdateFields {
  type: CreateArticleTypes.CREATE_ARTICLE_UPDATE_FIELDS;
  article: Article;
}

interface ArticleUpdateFields {
  [key: string]: any;
}

export function createArticleRequest(
  article: CreateArticleInput,
): CreateArticleRequest {
  return {
    type: CreateArticleTypes.CREATE_ARTILCE_REQUEST,
    article,
  };
}

export function createArticleSucceeded(article: Article): CreateArticleSuccess {
  return {
    type: CreateArticleTypes.CREATE_ARTICLE_SUCCESS,
    article,
  };
}

export function createArticleFailed(error: Error): CreateArticleError {
  return {
    type: CreateArticleTypes.CREATE_ARTICLE_ERROR,
    error,
  };
}

export function resetError(): CreateArticleResetError {
  return {
    type: CreateArticleTypes.CREATE_ARTICLE_RESET_ERROR,
  };
}

export function informationPopup(value: boolean): InformationPopup {
  return {
    type: CreateArticleTypes.OPEN_INFORMATION_POPUP,
    value,
  };
}

export function createArticleUpdateFields(
  article: ArticleUpdateFields,
): CreateArticleUpdateFields {
  return {
    type: CreateArticleTypes.CREATE_ARTICLE_UPDATE_FIELDS,
    article,
  };
}

export function createArticleReset(): CreateArticleReset {
  return {
    type: CreateArticleTypes.CREATE_ARTICLE_RESET,
  };
}
