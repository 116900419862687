/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  ImageCourseRequiredException,
  TitleException,
  VideoUrlRequiredException,
} from './exceptions';
import { CreateCourseItemState } from './reducer';

export function validateCreateCourseItem(state: CreateCourseItemState, action) {
  const { updates } = action;
  const {
    description_ru,
    description_ky,
    description_en,
    duration,
    name_ru,
    name_ky,
    name_en,
    image_ru,
    image_ky,
    image_en,
    parentId,
    videoUrl,
  } = updates;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (updates.hasOwnProperty('parentId')) {
    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          parentId: { $set: parentId },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('description_ru')) {
    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              description: {
                $set: update(state.courseItem.item.description, {
                  ru: { $set: description_ru },
                }),
              },
            }),
          },
          description: {
            $set: update(state.courseItem.description, {
              ru: { $set: description_ru },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('description_ky')) {
    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              description: {
                $set: update(state.courseItem.item.description, {
                  ky: { $set: description_ky },
                }),
              },
            }),
          },
          description: {
            $set: update(state.courseItem.description, {
              ky: { $set: description_ky },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('description_en')) {
    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              description: {
                $set: update(state.courseItem.item.description, {
                  en: { $set: description_en },
                }),
              },
            }),
          },
          description: {
            $set: update(state.courseItem.description, {
              en: { $set: description_en },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('duration')) {
    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              duration: { $set: duration },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_ru')) {
    if (!name_ru) {
      change.nameRuError = {
        $set: new TitleException(),
      };
    } else {
      change['$unset'] = ['nameRuError'];
    }

    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              title: {
                $set: update(state.courseItem.item.title, {
                  ru: { $set: name_ru },
                }),
              },
            }),
          },
          title: {
            $set: update(state.courseItem.title, {
              ru: { $set: name_ru },
            }),
          },
          name: {
            $set: update(state.courseItem.name, {
              ru: { $set: name_ru },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_ky')) {
    if (!name_ky) {
      change.nameKyError = {
        $set: new TitleException(),
      };
    } else {
      change['$unset'] = ['nameKyError'];
    }

    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              title: {
                $set: update(state.courseItem.item.title, {
                  ky: { $set: name_ky },
                }),
              },
            }),
          },
          title: {
            $set: update(state.courseItem.title, {
              ky: { $set: name_ky },
            }),
          },
          name: {
            $set: update(state.courseItem.name, {
              ky: { $set: name_ky },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_en')) {
    if (!name_en) {
      change.nameEnError = {
        $set: new TitleException(),
      };
    } else {
      change['$unset'] = ['nameEnError'];
    }

    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              title: {
                $set: update(state.courseItem.item.title, {
                  en: { $set: name_en },
                }),
              },
            }),
          },
          title: {
            $set: update(state.courseItem.title, {
              en: { $set: name_en },
            }),
          },
          name: {
            $set: update(state.courseItem.name, {
              en: { $set: name_en },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('image_ru')) {
    if (!image_ru) {
      change.imageRuError = {
        $set: new ImageCourseRequiredException(),
      };
    } else {
      change['$unset'] = ['imageRuError'];
    }

    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              previewImage: {
                $set: update(state.courseItem.item.previewImage, {
                  ru: { $set: image_ru },
                }),
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('image_ky')) {
    if (!image_ky) {
      change.imageKyError = {
        $set: new ImageCourseRequiredException(),
      };
    } else {
      change['$unset'] = ['imageKyError'];
    }

    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              previewImage: {
                $set: update(state.courseItem.item.previewImage, {
                  ky: { $set: image_ky },
                }),
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('image_en')) {
    if (!image_en) {
      change.imageEnError = {
        $set: new ImageCourseRequiredException(),
      };
    } else {
      change['$unset'] = ['imageEnError'];
    }

    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              previewImage: {
                $set: update(state.courseItem.item.previewImage, {
                  en: { $set: image_en },
                }),
              },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('videoUrl')) {
    if (!videoUrl) {
      change.videoUrlError = {
        $set: new VideoUrlRequiredException(),
      };
    } else {
      change['$unset'] = ['videoUrlError'];
    }

    return update(state, {
      ...change,
      courseItem: {
        $set: update(state.courseItem, {
          item: {
            $set: update(state.courseItem.item, {
              url: {
                $set: videoUrl,
              },
            }),
          },
        }),
      },
    });
  }

  return state;
}
