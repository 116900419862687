/* External dependencies */
import gql from 'graphql-tag';

export const listUserActionsQuery = gql`
  query listUserActions($input: ListUserActionsInput!) {
    listUserActions(input: $input) {
      total
      usersActions {
        eventDate
        id
        operatorId
        status
        type
        updatedDate
        userId
        phone
        user {
          id
          firstName
          lastName
          patronymic
          avatar
          phone
        }
        statusMessage
        data {
          ... on ExchangePoints {
            prizes {
              id
              #name #TODO: uncomment,when the problem with localizations on backend will be resolved.
              avatar {
                url
              }
              status
              requiredPoints
              postDate
            }
            destinationAddress
            phone
            firstName
            lastName
            totalPoints
          }
          ... on WatchingVideo {
            position
            isCompleted
            __typename
          }
          ... on Purchase {
            __typename
            receiptFile
            parent {
              id
              coursePrice {
                value
                currency
              }
            }
            purchaseMethod {
              accountName
              accountNumber
              id
              logo
              name
              __typename
            }
          }
        }
      }
    }
  }
`;
