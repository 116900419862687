// External dependencies
import gql from 'graphql-tag';

export const listArticlesQuery = gql`
  query listArticles($input: ListArticlesInput!) {
    listArticles(input: $input) {
      total
      articles {
        articleType
        author {
          id
        }
        category
        clinic {
          id
        }
        contents {
          type
          data
        }
        heroMedia {
          imageUrl
          fullVideoUrl
        }
        id
        physician {
          id
        }
        pharmacist {
          id
        }
        synopsis
        title
        createDate
        slug
      }
    }
  }
`;
