/* External dependencies */
import gql from 'graphql-tag';

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateEntryInput!) {
    updateUser(input: $input) {
      typename: __typename
      ... on Clinic {
        id
        status
      }
      ... on Physician {
        id
        status
      }
      ... on Pharmacist {
        id
        status
      }
    }
  }
`;
