// External dependencies
import gql from 'graphql-tag';

export const listPrizesQuery = gql`
  query listPrizes($input: ListPrizesInput!) {
    listPrizes(input: $input) {
      total
      prizes {
        id
        name
        avatar {
          url
        }
        status
        requiredPoints
        postDate
        orderNumber
      }
    }
  }
`;
