// Exteral dependencies
import OneSignal from 'react-onesignal';

export async function runOneSignal(appId: string) {
  await OneSignal.init({
    appId,
    allowLocalhostAsSecureOrigin: true,
    serviceWorker: {
      skipWaiting: true,
    },
  });

  OneSignal.showSlidedownPrompt();
}

export async function updateOneSignalExternalUserId(userId?: string) {
  return !userId
    ? OneSignal.removeExternalUserId()
    : OneSignal.setExternalUserId(userId);
}
