// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { RequiredFieldException } from '@shared/utils/exceptions';

export default function validateCreateCourseFields(state, value) {
  const inputName = Object.keys(value)[0];
  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  switch (inputName) {
    case 'name_ru':
      if (!value[inputName]) {
        change.nameRuError = { $set: new RequiredFieldException() };
      } else {
        delete state['nameRuError'];
      }

      return update(state, {
        ...change,
        course: {
          name: {
            $set: update(state.course.name, {
              ru: { $set: value[inputName] },
            }),
          },
          title: {
            $set: update(state.course.title, {
              ru: { $set: value[inputName] },
            }),
          },
        },
      });

    case 'name_en':
      if (!value[inputName]) {
        change.nameEnError = { $set: new RequiredFieldException() };
      } else {
        delete state['nameEnError'];
      }

      return update(state, {
        ...change,
        course: {
          name: {
            $set: update(state.course.name, {
              en: { $set: value[inputName] },
            }),
          },
          title: {
            $set: update(state.course.title, {
              en: { $set: value[inputName] },
            }),
          },
        },
      });

    case 'name_ky':
      if (!value[inputName]) {
        change.nameKyError = { $set: new RequiredFieldException() };
      } else {
        delete state['nameKyError'];
      }

      return update(state, {
        ...change,
        course: {
          name: {
            $set: update(state.course.name, {
              ky: { $set: value[inputName] },
            }),
          },
          title: {
            $set: update(state.course.title, {
              ky: { $set: value[inputName] },
            }),
          },
        },
      });

    case 'description_en':
      if (!value[inputName]) {
        change.descriptionEnError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['descriptionEnError'];
      }

      return update(state, {
        ...change,
        course: {
          description: {
            $set: update(state.course.description, {
              en: { $set: value[inputName] },
            }),
          },
        },
      });

    case 'description_ru':
      if (!value[inputName]) {
        change.descriptionRuError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['descriptionRuError'];
      }

      return update(state, {
        ...change,
        course: {
          description: {
            $set: update(state.course.description, {
              ru: { $set: value[inputName] },
            }),
          },
        },
      });

    case 'description_ky':
      if (!value[inputName]) {
        change.descriptionKyError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['descriptionKyError'];
      }

      return update(state, {
        ...change,
        course: {
          description: {
            $set: update(state.course.description, {
              ky: { $set: value[inputName] },
            }),
          },
        },
      });

    case 'image_en':
      if (!value[inputName]) {
        change.imageEnError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['imageEnError'];
      }

      return update(state, {
        ...change,
        course: {
          image: {
            $set: update(state.course.image, {
              en: { $set: value[inputName] },
            }),
          },
          video: {
            $set: update(state.course.video, {
              en: {
                $set: update(state.course.video.en, {
                  previewImage: { $set: value[inputName] },
                }),
              },
            }),
          },
        },
      });

    case 'image_ru':
      if (!value[inputName]) {
        change.imageRuError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['imageRuError'];
      }

      return update(state, {
        ...change,
        course: {
          image: {
            $set: update(state.course.image, {
              ru: { $set: value[inputName] },
            }),
          },
          video: {
            $set: update(state.course.video, {
              ru: {
                $set: update(state.course.video.ru, {
                  previewImage: { $set: value[inputName] },
                }),
              },
            }),
          },
        },
      });

    case 'image_ky':
      if (!value[inputName]) {
        change.imageKyError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['imageKyError'];
      }

      return update(state, {
        ...change,
        course: {
          image: {
            $set: update(state.course.image, {
              ky: { $set: value[inputName] },
            }),
          },
          video: {
            $set: update(state.course.video, {
              ky: {
                $set: update(state.course.video.ky, {
                  previewImage: { $set: value[inputName] },
                }),
              },
            }),
          },
        },
      });

    case 'video_en':
      if (!value[inputName]) {
        change.videoEnError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['videoEnError'];
      }

      return update(state, {
        ...change,
        course: {
          video: {
            $set: update(state.course.video, {
              en: {
                $set: update(state.course.video.en, {
                  url: { $set: value[inputName] },
                }),
              },
            }),
          },
        },
      });

    case 'video_ru':
      if (!value[inputName]) {
        change.videoRuError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['videoRuError'];
      }

      return update(state, {
        ...change,
        course: {
          video: {
            $set: update(state.course.video, {
              ru: {
                $set: update(state.course.video.ru, {
                  url: { $set: value[inputName] },
                }),
              },
            }),
          },
        },
      });

    case 'video_ky':
      if (!value[inputName]) {
        change.videoKyError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['videoKyError'];
      }

      return update(state, {
        ...change,
        course: {
          video: {
            $set: update(state.course.video, {
              ky: {
                $set: update(state.course.video.ky, {
                  url: { $set: value[inputName] },
                }),
              },
            }),
          },
        },
      });

    case 'ageRestrictions':
      if (!value[inputName]) {
        change.ageRestrictionsError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['ageRestrictionsError'];
      }

      return update(state, {
        ...change,
        course: {
          ageRestrictions: { $set: value[inputName] },
        },
      });

    case 'coursePrice':
      if (!value[inputName]) {
        change.coursePriceError = {
          $set: new RequiredFieldException(),
        };
      } else {
        delete state['coursePriceError'];
      }

      return update(state, {
        ...change,
        course: {
          coursePrice: {
            $set: update(state.course.coursePrice, {
              value: { $set: value[inputName] },
            }),
          },
        },
      });

    default:
      return state;
  }
}
