export enum DoctorAndClinicActionTypes {
  CHANGE_USER_FILTER = 'CHANGE_USER_FILTER',
  ADD_SEARCH_STRING = 'ADD_SEARCH_STRING',
  ADD_CURRENT_PAGE = 'ADD_CURRENT_PAGE',
  ADD_ACTIVE_TAB = 'ADD_ACTIVE_TAB',
}

export type DoctorAndClinicTypes =
  | ChangeUserFilter
  | AddSearchString
  | AddCurrentPage
  | AddActiveTab;
export interface ChangeUserFilter {
  filter?: string;
  type: DoctorAndClinicActionTypes.CHANGE_USER_FILTER;
}

export interface AddSearchString {
  searchString: string;
  type: DoctorAndClinicActionTypes.ADD_SEARCH_STRING;
}

export interface AddCurrentPage {
  currentPage: number;
  type: DoctorAndClinicActionTypes.ADD_CURRENT_PAGE;
}

export interface AddActiveTab {
  activeTab: string;
  type: DoctorAndClinicActionTypes.ADD_ACTIVE_TAB;
}

export function changeUserFilter(filter: string): ChangeUserFilter {
  return {
    filter,
    type: DoctorAndClinicActionTypes.CHANGE_USER_FILTER,
  };
}

export function addSearchString(searchString: string): AddSearchString {
  return {
    searchString,
    type: DoctorAndClinicActionTypes.ADD_SEARCH_STRING,
  };
}

export function addCurrentPage(currentPage: number): AddCurrentPage {
  return {
    currentPage,
    type: DoctorAndClinicActionTypes.ADD_CURRENT_PAGE,
  };
}

export function addActiveTab(activeTab): AddActiveTab {
  return {
    activeTab,
    type: DoctorAndClinicActionTypes.ADD_ACTIVE_TAB,
  };
}
