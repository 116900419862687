// Local depedencies
import { UserStatus } from '../../types';

export enum UpdateUserActionsTypes {
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
  UPDATE_USER_RESET = 'UPDATE_USER_RESET',
}

export type UpdateUserAction = UpdateUserRequest | UpdateUserSuccess | UpdateUserError | UpdateUserReset;
export interface UpdateUserInput {
  status?: UserStatus;
}

export interface UpdateUser extends UpdateUserInput {
  id: string;
}

export interface UpdateUserRequest {
  type: UpdateUserActionsTypes.UPDATE_USER_REQUEST;
  updateUserInput: UpdateUser;
}

export interface UpdateUserSuccess {
  type: UpdateUserActionsTypes.UPDATE_USER_SUCCESS;
  user: UpdateUser;
}

export interface UpdateUserError {
  type: UpdateUserActionsTypes.UPDATE_USER_ERROR;
  error: Error;
}

export interface UpdateUserReset {
  type: UpdateUserActionsTypes.UPDATE_USER_RESET;
}

export function updateUserRequest(updateUserInput: UpdateUser): UpdateUserRequest {
  return {
    type: UpdateUserActionsTypes.UPDATE_USER_REQUEST,
    updateUserInput,
  };
}

export function updateUserSucceeded(user: UpdateUser): UpdateUserSuccess {
  return {
    type: UpdateUserActionsTypes.UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserFailed(error: Error): UpdateUserError {
  return {
    type: UpdateUserActionsTypes.UPDATE_USER_ERROR,
    error,
  };
}

export function updateUserReset(): UpdateUserReset {
  return {
    type: UpdateUserActionsTypes.UPDATE_USER_RESET,
  };
}
