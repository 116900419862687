export abstract class UIException extends Error {
  public code: string;
}

export class PhoneNumberRequiredException extends UIException {
  code = 'PhoneNumberRequiredException';
}

export class PhoneNumberInvalidException extends UIException {
  code = 'PhoneNumberInvalidException';
}

export class PhoneNumberNotKyrgyzstanException extends UIException {
  code = 'PhoneNumberNotKyrgyzstanException';
}
