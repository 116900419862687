// External dependencies
import { getClient } from '@app/clients/medcheck';
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { Course } from '../../types';
import {
  GetCourseAction,
  GetCourseActionTypes,
  getCourseFailed,
  GetCourseRequest,
  getCourseSucceeded,
  updateCourseFailed,
  UpdateCourseRequest,
  updateCourseSucceeded,
} from './action';
import { updateCourseMutation } from './mutation';
import { getCourseQuery } from './query';

export function getCourseEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetCourseAction) =>
        action.type === GetCourseActionTypes.GET_COURSE_REQUEST,
    ),
    switchMap((action: GetCourseRequest) =>
      getCourse(action)
        .then(getCourseSucceeded)
        .catch((error: Error) => getCourseFailed(error)),
    ),
  );
}

export async function getCourse({ id }: GetCourseRequest): Promise<Course> {
  const graphQLClient = await getClient();

  const {
    data: { getCourse },
  } = await graphQLClient.query({
    query: getCourseQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getCourse as Course;
}

export function updateCourseEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetCourseAction) =>
        action.type === GetCourseActionTypes.UPDATE_COURSE_REQUEST,
    ),
    switchMap((action: UpdateCourseRequest) =>
      updateCourse(action)
        .then((course) => updateCourseSucceeded(course))
        .catch(updateCourseFailed),
    ),
  );
}

export async function updateCourse(
  action: UpdateCourseRequest,
): Promise<Course> {
  const graphQLClient = await getClient();
  const { updatedCourse } = action;

  const {
    data: { course },
  } = await graphQLClient.mutate({
    mutation: updateCourseMutation,
    variables: {
      input: updatedCourse,
    },
  });

  return course as Course;
}
