/* Local dependencies */
import { UIException } from '@app/redux/exceptions';

export class RequiredFieldException extends UIException {
  code = 'RequiredFieldException';
}

export class MaxLength100Exception extends UIException {
  code = 'MaxLength100Exception';
}

export class VideoUrlRequiredException extends UIException {
  code = 'VideoUrlRequiredException';
}

export class UploadImageRequiredException extends UIException {
  code = 'UploadImageRequiredException';
}
export class TitleException extends UIException {
  code = 'TitleException';
}

export class DescriptionException extends UIException {
  code = 'DescriptionException';
}
