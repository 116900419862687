/* Local dependencies */
import { UIException } from '@app/redux/exceptions';

export class VideoUrlRequiredException extends UIException {
  code = 'VideoUrlRequiredException';
}

export class ImageCourseRequiredException extends UIException {
  code = 'ImageCourseRequiredException';
}

export class TitleException extends UIException {
  code = 'TitleException';
}
