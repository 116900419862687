// External depedencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '@app/clients/medcheck';
import {
  ListDrugsAction,
  ListDrugsActionTypes,
  ListDrugsSuccess,
  listDrugsFailed,
  listDrugsSucceeded,
  ListDrugsRequest,
} from './actions';
import { listDrugsQuery } from './queries';

export default function listDrugsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListDrugsAction) =>
        action.type === ListDrugsActionTypes.LIST_DRUGS_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListDrugsRequest) =>
      listDrugs(action, state$?.value?.listDrugs?.size).catch((error) =>
        listDrugsFailed(error),
      ),
    ),
  );
}

export async function listDrugs(
  { searchString, currentPage }: ListDrugsRequest,
  size: number,
): Promise<ListDrugsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listDrugs: { drugs, total },
    },
  } = await graphQLClient.query({
    query: listDrugsQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        size: size,
        query: searchString,
      },
    },
  });

  return listDrugsSucceeded(total, drugs);
}
