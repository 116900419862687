// External dependencies
import gql from 'graphql-tag';

export const listCoursesQuery = gql`
  query listCourses($input: ListCoursesInput!) {
    listCourses(input: $input) {
      total
      courses {
        id
        ageRestrictions
        courseItems {
          id
          name {
            en
            ru
            ky
          }
          status
          typename: __typename
        }
        courseItemsCount
        coursePrice {
          isFrom
          value
          currency
        }
        description {
          en
          ru
          ky
        }
        image {
          en
          ru
          ky
        }
        isLiked
        purchaseStatus
        lastReview {
          id
          parentId
          message
          rating
          likeCount
          likeState
        }
        likesCount
        name {
          en
          ru
          ky
        }
        parentId
        physicianId
        postDate
        reviews {
          id
          parentId
          message
          rating
          likeCount
          likeState
        }
        reviewsCount
        status
        title {
          en
          ru
          ky
        }
        totalDuration
        video {
          en {
            previewImage
            url
            title
            description
            duration
          }
          ru {
            previewImage
            url
            title
            description
            duration
          }
          ky {
            previewImage
            url
            title
            description
            duration
          }
        }
        typename: __typename
      }
    }
  }
`;
