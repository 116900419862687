// External dependencies
import React from 'react';
import { Trans } from 'react-i18next';

/* Local dependencies */
import { ActionType, UserActionStatus } from '../types';

export const tableColumnsForPurchase = [
  {
    field: 'userAvatar',
    title: <Trans>userAvatar</Trans>,
  },
  {
    field: 'firstName',
    title: <Trans>firstName</Trans>,
  },
  {
    field: 'phone',
    title: <Trans>phone</Trans>,
  },
  {
    field: 'eventPaymentDate',
    title: <Trans>eventPaymentDate</Trans>,
  },
  {
    field: 'status',
    title: <Trans>paymentStatus</Trans>,
  },
  {
    field: 'statusMessage',
    title: <Trans>statusMessage</Trans>,
  },
  {
    field: 'check',
    title: <Trans>checkImage</Trans>,
  },
  {
    field: 'coursePrice',
    title: <Trans>coursePrice</Trans>,
  },
  {
    field: 'action',
    title: <Trans>confirmActionUser</Trans>,
  },
];

export const tableColumnsForExchangePoints = [
  {
    field: 'fullName',
    title: <Trans>fullName</Trans>,
  },
  {
    field: 'phone',
    title: <Trans>phone</Trans>,
  },
  {
    field: 'destinationAddress',
    title: <Trans>address</Trans>,
  },
  {
    field: 'totalPoints',
    title: <Trans>totalPoints</Trans>,
  },
  {
    field: 'status',
    title: <Trans>paymentStatus</Trans>,
  },
  {
    field: 'statusMessage',
    title: <Trans>statusMessage</Trans>,
  },
  {
    field: 'action',
    title: <Trans>confirmActionUser</Trans>,
  },
];

export function getWordForm(number: number, language: string) {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  switch (language) {
    case 'ru':
      if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return 'points';
      }
      switch (lastDigit) {
        case 1:
          return 'point';
        case 2:
        case 3:
        case 4:
          return 'pointsWithAForRu';
        default:
          return 'points';
      }
    case 'en':
      return number === 1 ? 'point' : 'points';
    default:
      return 'point';
  }
}

export const userActionTypes = [ActionType.PURCHASE, ActionType.EXCHANGEPOINTS];

export const userActionTypesForMeducationManager = [ActionType.EXCHANGEPOINTS];

export function isUserActionForExchangePoint(type: string) {
  return type === ActionType.EXCHANGEPOINTS;
}

export const userActionStatusTabs = [
  { label: UserActionStatus.IN_REVIEW },
  { label: UserActionStatus.APPROVED },
  { label: UserActionStatus.CANCELED },
];

export const statusMessageMaxLength = 100;
