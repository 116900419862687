// External dependencies
import gql from 'graphql-tag';

export const updateArticleMutation = gql`
  mutation updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
      articleType
      category
      contents {
        type
        data
      }
      heroMedia {
        imageUrl
        fullVideoUrl
      }
      readTimeMinute
      synopsis
      title
    }
  }
`;
