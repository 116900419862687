// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DEFAULT_PAGE_SIZE } from '@shared/utils/helpers';
import { ListPrizeSort } from '../../lib/types';
import {
  DEFAULT_PRIZES_SORT,
  ListPrizesAction,
  ListPrizesActionTypes,
} from './actions';

export interface ListPrizesState {
  currentPage: number;
  error?: Error;
  from?: number;
  loading: boolean;
  locale?: string;
  searchString: string;
  size: number;
  sort: ListPrizeSort;
  total: number;
  prizes?: any;
}

export const initialPrizesState: ListPrizesState = {
  currentPage: 1,
  loading: false,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_PRIZES_SORT,
  total: 0,
  prizes: [],
};

export default function listPrizesReducer(
  state = initialPrizesState,
  action: ListPrizesAction,
) {
  switch (action.type) {
    case ListPrizesActionTypes.LIST_PRIZES_REQUEST:
      const { searchString, currentPage, sort } = action;
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        sort: { $set: sort },
      });

    case ListPrizesActionTypes.LIST_PRIZES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        prizes: { $set: action.prizes },
        total: { $set: action.total },
      });

    case ListPrizesActionTypes.LIST_PRIZES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListPrizesActionTypes.LIST_PRIZES_RESET:
      return initialPrizesState;

    default:
      return state;
  }
}
