/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '@app/clients/medcheck';
import {
  CreateArticleActionTypes,
  CreateArticleTypes,
  createArticleFailed,
  createArticleSucceeded,
} from './action';
import { createArticleMutation } from './mutation';

export default function createArticleEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateArticleActionTypes) =>
        action.type === CreateArticleTypes.CREATE_ARTILCE_REQUEST,
    ),
    switchMap((action: CreateArticleActionTypes) =>
      createArticle(action)
        .then((article) => createArticleSucceeded(article))
        .catch((error) => createArticleFailed(error)),
    ),
  );
}

export async function createArticle(action): Promise<any> {
  const graphQLClient = await getClient();
  const { article } = action;

  const newArticle = {
    ...article,
    contents: [
      {
        ...article.contents[0],
      },
    ],
  };

  const {
    data: { createArticle },
  } = await graphQLClient.mutate({
    mutation: createArticleMutation,
    variables: {
      input: newArticle,
    },
  });

  return createArticle;
}
