// External depedencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '@app/clients/medcheck';
import {
  ListUsersAction,
  ListUsersActionTypes,
  ListUsersRequest,
  listUsersFailed,
  listUsersSucceeded,
} from './actions';
import { listUsersQuery } from './queries';

export default function listProAccountsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListUsersAction) =>
        action.type === ListUsersActionTypes.LIST_USERS_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListUsersRequest) => {
      return listUsers(action, state$?.value.users?.size)
        .then((data) => listUsersSucceeded(data.total, data.users))
        .catch((error) => listUsersFailed(error));
    }),
  );
}

export async function listUsers(
  { searchString, currentPage, filter, sort }: ListUsersRequest,
  size: number,
): Promise<any> {
  const graphQLClient = await getClient();

  const {
    data: {
      listUsers: { users, total },
    },
  } = await graphQLClient.query({
    query: listUsersQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        size: size,
        query: searchString,
        filter,
        sort,
      },
    },
  });

  return { total, users };
}
