/* Local dependencies */
import { UIException } from '@app/redux/exceptions';

export class CategoryLengthRequiredException extends UIException {
  code = 'CategoryLengthRequiredException';
}

export class DataLengthRequiredExpection extends UIException {
  code = 'DataLengthRequiredException';
}

export class ValidFormatForLinkRequiredException extends UIException {
  code = 'ValidFormatForLinkRequiredExpection';
}

export class ImageRequiredException extends UIException {
  code = 'ImageRequiredException';
}

export class TitleLengthRequiredException extends UIException {
  code = 'TitleLengthRequiredException';
}

export class ReadTimeMinuteRequiredException extends UIException {
  code = 'readingTimeException';
}
