// Local dependencies
import { Course, CreateCourseInput } from '../../types';

export enum CreateCourseActionTypes {
  CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST',
  CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS',
  CREATE_COURSE_ERROR = 'CREATE_COURSE_ERROR',
  RESET_CREATE_COURSE = 'RESET_CREATE_COURSE',
  RESET_CREATE_COURSE_ERROR = 'RESET_CREATE_COURSE_ERROR',
  CREATE_COURSE_UPDATE_FIELDS = 'CREATE_COURSE_UPDATE_FIELDS',
}

export interface CreateCourseRequest {
  type: CreateCourseActionTypes.CREATE_COURSE_REQUEST;
  course: CreateCourseInput;
}

export interface CreateCourseSuccess {
  type: CreateCourseActionTypes.CREATE_COURSE_SUCCESS;
  course: Course;
}

export interface CreateCourseError {
  type: CreateCourseActionTypes.CREATE_COURSE_ERROR;
  error: Error;
}

export interface CreateCourseUpdateFields {
  type: CreateCourseActionTypes.CREATE_COURSE_UPDATE_FIELDS;
  course: any;
}
export interface ResetCreateCourse {
  type: CreateCourseActionTypes.RESET_CREATE_COURSE;
}
export interface ResetError {
  type: CreateCourseActionTypes.RESET_CREATE_COURSE_ERROR;
}

export type CreateCourseAction =
  | CreateCourseRequest
  | CreateCourseSuccess
  | CreateCourseError
  | ResetCreateCourse
  | CreateCourseUpdateFields
  | ResetError;

export function createCourseRequest(
  course: CreateCourseInput,
): CreateCourseRequest {
  return {
    type: CreateCourseActionTypes.CREATE_COURSE_REQUEST,
    course,
  };
}

export function createCourseSuccess(course: Course): CreateCourseSuccess {
  return {
    type: CreateCourseActionTypes.CREATE_COURSE_SUCCESS,
    course,
  };
}

export function createCourseError(error: Error): CreateCourseError {
  return {
    type: CreateCourseActionTypes.CREATE_COURSE_ERROR,
    error,
  };
}

export function createCourseUpdateFields(
  course: any,
): CreateCourseUpdateFields {
  return {
    type: CreateCourseActionTypes.CREATE_COURSE_UPDATE_FIELDS,
    course,
  };
}

export function resetCreateCourse(): ResetCreateCourse {
  return {
    type: CreateCourseActionTypes.RESET_CREATE_COURSE,
  };
}

export function resetError(): ResetError {
  return {
    type: CreateCourseActionTypes.RESET_CREATE_COURSE_ERROR,
  };
}
