// External depedencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import {
  ListCoursesAction,
  ListCoursesActionTypes,
  listCoursesFailed,
  ListCoursesRequest,
  listCoursesSucceeded,
  ListCoursesSuccess,
} from './actions';
import { listCoursesQuery } from './queries';
import { getClient } from '@app/clients/medcheck';

export default function listCoursesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListCoursesAction) =>
        action.type === ListCoursesActionTypes.LIST_COURSES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListCoursesRequest) => {
      return listCourses(action, state$?.value?.listCourses).catch(
        listCoursesFailed,
      );
    }),
  );
}

export async function listCourses(
  { searchString, filter }: ListCoursesRequest,
  state,
): Promise<ListCoursesSuccess> {
  const { currentPage, size } = state;
  const graphQLClient = await getClient();

  const {
    data: {
      listCourses: { courses, total },
    },
  } = await graphQLClient.query({
    query: listCoursesQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        size,
        query: searchString,
        filter,
      },
    },
  });

  return listCoursesSucceeded(total, courses);
}
