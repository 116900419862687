// External dependencies
import update from 'immutability-helper';
// Local dependencies
import {
  Article,
  ArticleCategory,
  ArticleType,
  ContentType,
} from '../../types';
import { GetArticleAction, GetArticleActionTypes } from './actions';
import validateUpdateArticleFields from './validation';

export interface GetArticleState {
  article?: Article;
  editedArticle?: Article;
  categoryError?: Error;
  dataError?: Error;
  error?: Error;
  fullVideoUrlError?: Error;
  isSuccess: boolean;
  isFormChanged?: boolean;
  isConfirmationPopupOpen?: boolean;
  loading: boolean;
  readTimeMinuteError?: Error;
  synopsisError?: Error;
  titleError?: Error;
}

export const initialGetArticleState: GetArticleState = {
  loading: false,
  isSuccess: false,
  isConfirmationPopupOpen: false,
  editedArticle: {},
  article: {
    articleType: ArticleType.OVERLAYED_CARD,
    category: ArticleCategory.MedCuts,
    contents: [
      {
        type: ContentType.MARKDOWN,
        data: '',
      },
    ],
    heroMedia: {
      imageUrl: '',
      fullVideoUrl: '',
    },
    synopsis: '',
    readTimeMinute: '',
    title: '',
  },
};

export default function getArticleReducer(
  state = initialGetArticleState,
  action: GetArticleAction,
) {
  switch (action.type) {
    case GetArticleActionTypes.GET_ARTICLE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetArticleActionTypes.GET_ARTICLE_SUCCESS:
      return update(state, {
        article: { $set: action.article },
        loading: { $set: false },
      });

    case GetArticleActionTypes.GET_ARTICLE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetArticleActionTypes.GET_ARTICLE_RESET:
      return initialGetArticleState;

    case GetArticleActionTypes.UPDATE_ARTICLE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isConfirmationPopupOpen: { $set: false },
      });

    case GetArticleActionTypes.UPDATE_ARTICLE_SUCCESS:
      const { article } = action;

      return update(state, {
        article: { $set: article },
        isSuccess: { $set: true },
        loading: { $set: false },
      });

    case GetArticleActionTypes.UPDATE_ARTICLE_ERROR:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: action.error },
      });

    case GetArticleActionTypes.UPDATE_ARTICLE_UPDATE_VALUE:
      return validateUpdateArticleFields(state, action.updates);

    case GetArticleActionTypes.UPDATE_ARTICLE_ERROR_RESET:
      return update(state, {
        $unset: ['error'],
      });

    case GetArticleActionTypes.UPDATE_ARTICLE_OPEN_INFORMATION_POPUP:
      return update(state, {
        isConfirmationPopupOpen: { $set: action.value },
      });

    default:
      return state;
  }
}
