// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DEFAULT_PAGE_SIZE } from '@shared/utils/helpers';
import {
  ListDrugsAction,
  ListDrugsActionTypes,
  ListDrugsFilter,
} from './actions';
import { ListDrugsStatusFilter } from '@entities/drugs/lib/types';

export interface ListDrugsState {
  currentPage: number;
  error?: Error;
  from?: number;
  filter?: ListDrugsFilter;
  loading: boolean;
  locale?: string;
  searchString: string;
  size: number;
  total: number;
  drugs?: any;
}

export const initialDrugsState: ListDrugsState = {
  currentPage: 1,
  filter: {
    status: ListDrugsStatusFilter.ALL,
  },
  loading: false,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
  total: 0,
  drugs: [],
};

export default function listDrugsReducer(
  state = initialDrugsState,
  action: ListDrugsAction,
) {
  switch (action.type) {
    case ListDrugsActionTypes.LIST_DRUGS_REQUEST:
      const { searchString, currentPage, filter } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
      });

    case ListDrugsActionTypes.LIST_DRUGS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        drugs: { $set: action.drugs },
        total: { $set: action.total },
      });

    case ListDrugsActionTypes.LIST_DRUGS_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListDrugsActionTypes.LIST_DRUGS_RESET:
      return initialDrugsState;

    default:
      return state;
  }
}
