export enum BannerActionType {
  CLOSE_BANNER = 'CLOSE_BANNER',
}

export type BannerActionsTypes = CloseBanner;

export interface CloseBanner {
  type: BannerActionType.CLOSE_BANNER;
}

export function closeBanner(): CloseBanner {
  return {
    type: BannerActionType.CLOSE_BANNER,
  };
}
