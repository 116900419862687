// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { UserAction } from '../../types';
import {
  UpdateUserActionForManager,
  UpdateUserActionForManagerTypes,
} from './actions';
import { validateUpdateUserAction } from './validation';

export interface UpdateUserActionState {
  error?: Error;
  loading: boolean;
  isSuccess: boolean;
  userAction: UserAction;
  statusMessage: string;
  statusMessageError?: Error;
}

export const initialStateUpdateUserActionForManager: UpdateUserActionState = {
  loading: false,
  isSuccess: false,
  userAction: {
    data: null,
    eventDate: null,
    id: '',
    status: null,
    type: null,
    user: null,
    updatedDate: '',
    operatorId: '',
  },
  statusMessage: '',
};

export default function updateUserActionForManagerReducer(
  state = initialStateUpdateUserActionForManager,
  action: UpdateUserActionForManager,
) {
  switch (action.type) {
    case UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_REQUEST:
      return update(state, {
        loading: { $set: true },
        isSuccess: { $set: false },
      });

    case UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_SUCCESS:
      const { userAction } = action;
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        userAction: { $set: userAction },
      });

    case UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_ERROR:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: action.error },
      });

    case UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_UPDATE_FIELD:
      return validateUpdateUserAction(state, action.updates);

    case UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_RESET:
      return initialStateUpdateUserActionForManager;

    default:
      return state;
  }
}
