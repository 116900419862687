// Loacl dependencies
import { ListPrizeSort, Prize } from '@entities/prizes/lib/types';

export enum CreatePrizeActionTypes {
  CREATE_PRIZE_ERROR = 'CREATE_PRIZE_ERROR',
  CREATE_PRIZE_REQUEST = 'CREATE_PRIZE_REQUEST',
  CREATE_PRIZE_RESET = 'CREATE_PRIZE_RESET',
  CREATE_PRIZE_SUCCESS = 'CREATE_PRIZE_SUCCESS',
  CREATE_PRIZE_OPEN_CLOSE_FORM = 'CREATE_PRIZE_OPEN_CLOSE_FORM',
  CREATE_PRIZE_UPDATE_FIELDS = 'CREATE_PRIZE_UPDATE_FIELDS',
  CREATE_PRIZE_OPEN_CONFIRMATION_POPUP = 'CREATE_PRIZE_OPEN_CONFIRMATION_POPUP',
}

export type CreatePrizeAction =
  | CreatePrizeRequest
  | CreatePrizeSuccess
  | CreatePrizeError
  | CreatePrizeReset
  | CreatePrizeOpenCloseForm
  | CreatePrizeUpdateFields
  | CreatePrizeOpenConfirmationPopup;

export interface CreatePrizeRequest {
  type: CreatePrizeActionTypes.CREATE_PRIZE_REQUEST;
  prize: any; // todo PrizeInput
}

export interface CreatePrizeSuccess {
  type: CreatePrizeActionTypes.CREATE_PRIZE_SUCCESS;
  prize: Prize;
}

export interface CreatePrizeError {
  type: CreatePrizeActionTypes.CREATE_PRIZE_ERROR;
  error: Error;
}

export interface CreatePrizeReset {
  type: CreatePrizeActionTypes.CREATE_PRIZE_RESET;
}

export interface CreatePrizeOpenCloseForm {
  type: CreatePrizeActionTypes.CREATE_PRIZE_OPEN_CLOSE_FORM;
  value: boolean;
}

export interface CreatePrizeUpdateFields {
  type: CreatePrizeActionTypes.CREATE_PRIZE_UPDATE_FIELDS;
  prize: any;
}

export interface CreatePrizeOpenConfirmationPopup {
  type: CreatePrizeActionTypes.CREATE_PRIZE_OPEN_CONFIRMATION_POPUP;
  value: boolean;
}

export function createPrize(prize): CreatePrizeRequest {
  return {
    type: CreatePrizeActionTypes.CREATE_PRIZE_REQUEST,
    prize,
  };
}

export function createPrizeSucceeded(prize: Prize): CreatePrizeSuccess {
  return {
    type: CreatePrizeActionTypes.CREATE_PRIZE_SUCCESS,
    prize,
  };
}

export function createPrizeFailed(error: Error): CreatePrizeError {
  return {
    type: CreatePrizeActionTypes.CREATE_PRIZE_ERROR,
    error,
  };
}

export function createPrizeReset(): CreatePrizeReset {
  return {
    type: CreatePrizeActionTypes.CREATE_PRIZE_RESET,
  };
}

export function createPrizeFormOpenClose(
  value: boolean,
): CreatePrizeOpenCloseForm {
  return {
    type: CreatePrizeActionTypes.CREATE_PRIZE_OPEN_CLOSE_FORM,
    value,
  };
}

export function createPrizeUpdateFields(prize: any): CreatePrizeUpdateFields {
  return {
    type: CreatePrizeActionTypes.CREATE_PRIZE_UPDATE_FIELDS,
    prize,
  };
}

export function createPrizeOpenCloseConfirmationPopup(
  value: boolean,
): CreatePrizeOpenConfirmationPopup {
  return {
    type: CreatePrizeActionTypes.CREATE_PRIZE_OPEN_CONFIRMATION_POPUP,
    value,
  };
}
