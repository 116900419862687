/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '@app/clients/medcheck';
import {
  UpdateUser,
  UpdateUserAction,
  UpdateUserActionsTypes,
  UpdateUserRequest,
  updateUserFailed,
  updateUserSucceeded,
} from './action';
import { updateUserMutation } from './mutation';

export default function updateUserEpic(action$) {
  return action$.pipe(
    filter(
      (action: UpdateUserAction) =>
        action.type === UpdateUserActionsTypes.UPDATE_USER_REQUEST,
    ),
    switchMap((action: UpdateUserRequest) =>
      updateUser(action)
        .then((data) => updateUserSucceeded(data))
        .catch((error) => updateUserFailed(error)),
    ),
  );
}

export async function updateUser(action): Promise<UpdateUser> {
  const graphQLClient = await getClient();
  const { updateUserInput } = action;

  const {
    data: { updateUser },
  } = await graphQLClient.mutate({
    mutation: updateUserMutation,
    variables: {
      input: updateUserInput,
    },
  });

  return updateUser as UpdateUser;
}
