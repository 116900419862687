// Local dependencies
import {
  Article,
  UpdateArticleInput,
  UpdateArticleItemInputFields,
} from '../../types';

export enum GetArticleActionTypes {
  GET_ARTICLE_REQUEST = 'GET_ARTICLE_REQUEST',
  GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS',
  GET_ARTICLE_ERROR = 'GET_ARTICLE_ERROR',
  GET_ARTICLE_RESET = 'GET_ARTICLE_RESET',
  UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST',
  UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS',
  UPDATE_ARTICLE_ERROR = 'UPDATE_ARTICLE_ERROR',
  UPDATE_ARTICLE_RESET = 'UPDATE_ARTICLE_RESET',
  UPDATE_ARTICLE_ERROR_RESET = 'UPDATE_ARTICLE_ERROR_RESET',
  UPDATE_ARTICLE_UPDATE_VALUE = 'UPDATE_ARTICLE_UPDATE_VALUE',
  UPDATE_ARTICLE_OPEN_INFORMATION_POPUP = 'UPDATE_ARTICLE_OPEN_INFORMATION_POPUP',
}

export interface GetArticleRequest {
  type: GetArticleActionTypes.GET_ARTICLE_REQUEST;
  id: string;
  slug?: string;
}

export interface GetArticleSuccess {
  type: GetArticleActionTypes.GET_ARTICLE_SUCCESS;
  article: Article;
}

export interface GetArticleError {
  type: GetArticleActionTypes.GET_ARTICLE_ERROR;
  error: Error;
}

export interface GetArticleReset {
  type: GetArticleActionTypes.GET_ARTICLE_RESET;
}

export interface UpdateArticleRequest {
  type: GetArticleActionTypes.UPDATE_ARTICLE_REQUEST;
  updatedArticle: UpdateArticleInput;
}

export interface UpdateArticleSuccess {
  type: GetArticleActionTypes.UPDATE_ARTICLE_SUCCESS;
  article: Article;
}

export interface UpdateArticleError {
  type: GetArticleActionTypes.UPDATE_ARTICLE_ERROR;
  error: Error;
}

export interface ResetUpdateArticle {
  type: GetArticleActionTypes.UPDATE_ARTICLE_RESET;
}

export interface UpdateArticleErrorReset {
  type: GetArticleActionTypes.UPDATE_ARTICLE_ERROR_RESET;
}

export interface UpdateArticleUpdateValue {
  type: GetArticleActionTypes.UPDATE_ARTICLE_UPDATE_VALUE;
  updates: UpdateArticleItemInputFields;
}

export interface UpdateArticleOpenConfirmationPopup {
  type: GetArticleActionTypes.UPDATE_ARTICLE_OPEN_INFORMATION_POPUP;
  value: boolean;
}

export type GetArticleAction =
  | GetArticleRequest
  | GetArticleSuccess
  | GetArticleError
  | GetArticleReset
  | UpdateArticleRequest
  | UpdateArticleSuccess
  | UpdateArticleError
  | ResetUpdateArticle
  | UpdateArticleErrorReset
  | UpdateArticleUpdateValue
  | UpdateArticleOpenConfirmationPopup;

export function getArticle(id: string, slug: string = ''): GetArticleRequest {
  return {
    type: GetArticleActionTypes.GET_ARTICLE_REQUEST,
    id,
    slug,
  };
}

export function getArticleSucceeded(article: Article): GetArticleSuccess {
  return {
    type: GetArticleActionTypes.GET_ARTICLE_SUCCESS,
    article,
  };
}

export function getArticleFailed(error: Error): GetArticleError {
  return {
    type: GetArticleActionTypes.GET_ARTICLE_ERROR,
    error,
  };
}

export function getArticleReset(): GetArticleReset {
  return {
    type: GetArticleActionTypes.GET_ARTICLE_RESET,
  };
}

export function updateArticleRequest(
  updatedArticle: UpdateArticleInput,
): UpdateArticleRequest {
  return {
    type: GetArticleActionTypes.UPDATE_ARTICLE_REQUEST,
    updatedArticle,
  };
}

export function updateArticleSucceeded(article: Article): UpdateArticleSuccess {
  return {
    type: GetArticleActionTypes.UPDATE_ARTICLE_SUCCESS,
    article,
  };
}

export function updateArticleFailed(error: Error): UpdateArticleError {
  return {
    type: GetArticleActionTypes.UPDATE_ARTICLE_ERROR,
    error,
  };
}

export function updateArticleReset(): ResetUpdateArticle {
  return {
    type: GetArticleActionTypes.UPDATE_ARTICLE_RESET,
  };
}

export function updateArticleErrorReset(): UpdateArticleErrorReset {
  return {
    type: GetArticleActionTypes.UPDATE_ARTICLE_ERROR_RESET,
  };
}

export function updateArticleUpdateField(
  updates: UpdateArticleItemInputFields,
): UpdateArticleUpdateValue {
  return {
    type: GetArticleActionTypes.UPDATE_ARTICLE_UPDATE_VALUE,
    updates,
  };
}

export function updateArticleOpenPopup(
  value: boolean,
): UpdateArticleOpenConfirmationPopup {
  return {
    type: GetArticleActionTypes.UPDATE_ARTICLE_OPEN_INFORMATION_POPUP,
    value,
  };
}
