// Loacl dependencies
import { Drug, ListDrugsStatusFilter } from '@entities/drugs/lib/types';

export enum ListDrugsActionTypes {
  LIST_DRUGS_ERROR = 'LIST_DRUGS_ERROR',
  LIST_DRUGS_REQUEST = 'LIST_DRUGS_REQUEST',
  LIST_DRUGS_RESET = 'LIST_DRUGS_RESET',
  LIST_DRUGS_SUCCESS = 'LIST_DRUGS_SUCCESS',
  LIST_DRUGS_SORT = 'LIST_DRUGS_SORT',
}

export type ListDrugsAction =
  | ListDrugsRequest
  | ListDrugsSuccess
  | ListDrugsError
  | ListDrugsReset;

export interface ListDrugsFilter {
  status: ListDrugsStatusFilter;
}

export const DEFAULT_DRUGS_SORT = {
  joinDate: '',
};

export const DEFAULT_DRUGS_FILTER = {
  status: ListDrugsStatusFilter.ALL,
};
export interface ListDrugsRequest {
  type: ListDrugsActionTypes.LIST_DRUGS_REQUEST;
  searchString: string;
  currentPage: number;
  filter?: ListDrugsFilter;
  size?: number;
  locale?: String;
}

export interface ListDrugsSuccess {
  type: ListDrugsActionTypes.LIST_DRUGS_SUCCESS;
  drugs: Drug[];
  total: number;
}

export interface ListDrugsError {
  type: ListDrugsActionTypes.LIST_DRUGS_ERROR;
  error: Error;
}

export interface ListDrugsReset {
  type: ListDrugsActionTypes.LIST_DRUGS_RESET;
}

export function listDrugs(
  searchString: string,
  currentPage: number = 1,
  filter = DEFAULT_DRUGS_FILTER,
): ListDrugsRequest {
  return {
    type: ListDrugsActionTypes.LIST_DRUGS_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listDrugsSucceeded(
  total: number,
  drugs: Drug[],
): ListDrugsSuccess {
  return {
    type: ListDrugsActionTypes.LIST_DRUGS_SUCCESS,
    drugs,
    total,
  };
}

export function listDrugsFailed(error: Error): ListDrugsError {
  return {
    type: ListDrugsActionTypes.LIST_DRUGS_ERROR,
    error,
  };
}

export function listDrugsReset(): ListDrugsReset {
  return {
    type: ListDrugsActionTypes.LIST_DRUGS_RESET,
  };
}
