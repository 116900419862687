// Local depedencies
import { UserAction, UserActionStatus } from '../../types';

export enum UpdateUserActionForManagerTypes {
  UPDATE_USER_ACTION_FOR_MANAGER_REQUEST = 'UPDATE_USER_ACTION_FOR_MANAGER_REQUEST',
  UPDATE_USER_ACTION_FOR_MANAGER_SUCCESS = 'UPDATE_USER_ACTION_FOR_MANAGER_SUCCESS',
  UPDATE_USER_ACTION_FOR_MANAGER_ERROR = 'UPDATE_USER_ACTION_FOR_MANAGER_ERROR',
  UPDATE_USER_ACTION_FOR_MANAGER_RESET = 'UPDATE_USER_ACTION_FOR_MANAGER_RESET',
  UPDATE_USER_ACTION_FOR_MANAGER_UPDATE_FIELD = 'UPDATE_USER_ACTION_FOR_MANAGER_UPDATE_FIELD',
}

export type UpdateUserActionForManager =
  | UpdateUserActionForManagerRequest
  | UpdateUserActionForManagerSuccess
  | UpdateUserActionForManagerError
  | UpdateUserActionForManagerReset
  | UpdateUserActionForManagerUpdateField;

export interface UpdateUserActionForManagerInput {
  id: string;
  status?: UserActionStatus;
  eventDate: number;
  statusMessage?: string;
}

export interface UpdateUserActionForManagerRequest {
  type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_REQUEST;
  updateUserActionForManagerInput: UpdateUserActionForManagerInput;
}

export interface UpdateUserActionForManagerSuccess {
  type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_SUCCESS;
  userAction: UserAction;
}

export interface UpdateUserActionForManagerError {
  type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_ERROR;
  error: Error;
}

export interface UpdateUserActionForManagerReset {
  type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_RESET;
}

export interface UpdateUserActionForManagerUpdateField {
  type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_UPDATE_FIELD;
  updates: any;
}

export function updateUserActionForManagerRequest(
  updateUserActionForManagerInput: UpdateUserActionForManagerInput,
): UpdateUserActionForManagerRequest {
  return {
    type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_REQUEST,
    updateUserActionForManagerInput,
  };
}

export function updateUserActionForManagerSucceeded(
  userAction: UserAction,
): UpdateUserActionForManagerSuccess {
  return {
    type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_SUCCESS,
    userAction,
  };
}

export function updateUserActionForManagerFailed(
  error: Error,
): UpdateUserActionForManagerError {
  return {
    type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_ERROR,
    error,
  };
}

export function updateUserActionForManagerReset(): UpdateUserActionForManagerReset {
  return {
    type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_RESET,
  };
}

export function updateUserActionForManagerUpdateField(
  updates: any,
): UpdateUserActionForManagerUpdateField {
  return {
    type: UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_UPDATE_FIELD,
    updates,
  };
}
