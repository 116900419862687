// External dependencies
import update from 'immutability-helper';

// Local dependencies
import {
  Article,
  ArticleCategory,
  ArticleType,
  ContentType,
} from '@entities/articles/types';
import { CreateArticleActionTypes, CreateArticleTypes } from './action';
import validateCreateArticleFields from './validation';

export interface CreateArticleState {
  article?: Article;
  sendingArticle?: Article;
  categoryError?: Error;
  dataError?: Error;
  error?: Error;
  fullVideoUrlError?: Error;
  isSuccess: boolean;
  isFormChanged?: boolean;
  isConfirmationPopupOpen?: boolean;
  loading: boolean;
  readTimeMinuteError?: Error;
  synopsisError?: Error;
  titleError?: Error;
}

const createArticleInitialState: CreateArticleState = {
  loading: false,
  isSuccess: false,
  isConfirmationPopupOpen: false,
  sendingArticle: {
    articleType: ArticleType.OVERLAYED_CARD,
    category: ArticleCategory.MedCuts,
    contents: [
      {
        type: ContentType.MARKDOWN,
        data: '',
      },
    ],
  },
  article: {
    articleType: ArticleType.OVERLAYED_CARD,
    category: ArticleCategory.MedCuts,
    contents: [
      {
        type: ContentType.MARKDOWN,
        data: '',
      },
    ],
    heroMedia: {
      imageUrl: '',
      fullVideoUrl: '',
    },
    readTimeMinute: null,
    synopsis: '',
    title: '',
  },
};

export default function createArticleReducer(
  state = createArticleInitialState,
  action: CreateArticleActionTypes,
) {
  switch (action.type) {
    case CreateArticleTypes.CREATE_ARTILCE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case CreateArticleTypes.CREATE_ARTICLE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        isConfirmationPopupOpen: { $set: false },
        article: { $set: action.article },
      });

    case CreateArticleTypes.CREATE_ARTICLE_ERROR:
      return update(state, {
        loading: { $set: false },
        isConfirmationPopupOpen: { $set: false },
        error: { $set: action.error },
      });

    case CreateArticleTypes.CREATE_ARTICLE_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case CreateArticleTypes.OPEN_INFORMATION_POPUP:
      return update(state, {
        isConfirmationPopupOpen: { $set: action.value },
      });

    case CreateArticleTypes.CREATE_ARTICLE_RESET:
      return createArticleInitialState;

    case CreateArticleTypes.CREATE_ARTICLE_UPDATE_FIELDS:
      return validateCreateArticleFields(state, action.article);

    default:
      return state;
  }
}
