exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-admin-articles-article-details-index-tsx": () => import("./../../../src/pages/admin/articles/article-details/index.tsx" /* webpackChunkName: "component---src-pages-admin-articles-article-details-index-tsx" */),
  "component---src-pages-admin-articles-index-tsx": () => import("./../../../src/pages/admin/articles/index.tsx" /* webpackChunkName: "component---src-pages-admin-articles-index-tsx" */),
  "component---src-pages-admin-courses-course-details-index-tsx": () => import("./../../../src/pages/admin/courses/course-details/index.tsx" /* webpackChunkName: "component---src-pages-admin-courses-course-details-index-tsx" */),
  "component---src-pages-admin-courses-create-course-index-tsx": () => import("./../../../src/pages/admin/courses/create-course/index.tsx" /* webpackChunkName: "component---src-pages-admin-courses-create-course-index-tsx" */),
  "component---src-pages-admin-courses-create-course-item-index-tsx": () => import("./../../../src/pages/admin/courses/create-course-item/index.tsx" /* webpackChunkName: "component---src-pages-admin-courses-create-course-item-index-tsx" */),
  "component---src-pages-admin-courses-index-tsx": () => import("./../../../src/pages/admin/courses/index.tsx" /* webpackChunkName: "component---src-pages-admin-courses-index-tsx" */),
  "component---src-pages-admin-create-article-index-tsx": () => import("./../../../src/pages/admin/create-article/index.tsx" /* webpackChunkName: "component---src-pages-admin-create-article-index-tsx" */),
  "component---src-pages-admin-drugs-index-tsx": () => import("./../../../src/pages/admin/drugs/index.tsx" /* webpackChunkName: "component---src-pages-admin-drugs-index-tsx" */),
  "component---src-pages-admin-prizes-index-tsx": () => import("./../../../src/pages/admin/prizes/index.tsx" /* webpackChunkName: "component---src-pages-admin-prizes-index-tsx" */),
  "component---src-pages-admin-user-actions-index-tsx": () => import("./../../../src/pages/admin/user-actions/index.tsx" /* webpackChunkName: "component---src-pages-admin-user-actions-index-tsx" */),
  "component---src-pages-admin-users-index-tsx": () => import("./../../../src/pages/admin/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-index-tsx" */),
  "component---src-pages-admin-users-user-details-index-tsx": () => import("./../../../src/pages/admin/users/user-details/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-details-index-tsx" */),
  "component---src-pages-articles-article-tsx": () => import("./../../../src/pages/articles/article.tsx" /* webpackChunkName: "component---src-pages-articles-article-tsx" */),
  "component---src-pages-articles-category-tsx": () => import("./../../../src/pages/articles/category.tsx" /* webpackChunkName: "component---src-pages-articles-category-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-for-doctors-index-tsx": () => import("./../../../src/pages/for-doctors/index.tsx" /* webpackChunkName: "component---src-pages-for-doctors-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-verify-code-index-tsx": () => import("./../../../src/pages/login/verify-code/index.tsx" /* webpackChunkName: "component---src-pages-login-verify-code-index-tsx" */),
  "component---src-pages-meducation-index-tsx": () => import("./../../../src/pages/meducation/index.tsx" /* webpackChunkName: "component---src-pages-meducation-index-tsx" */),
  "component---src-pages-meducation-partners-index-tsx": () => import("./../../../src/pages/meducation/partners/index.tsx" /* webpackChunkName: "component---src-pages-meducation-partners-index-tsx" */),
  "component---src-pages-pro-package-index-tsx": () => import("./../../../src/pages/pro-package/index.tsx" /* webpackChunkName: "component---src-pages-pro-package-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-search-user-tsx": () => import("./../../../src/pages/search/user.tsx" /* webpackChunkName: "component---src-pages-search-user-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("./../../../src/pages/terms-of-use/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */)
}

