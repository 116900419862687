// Loacl dependencies
import { ListPrizeSort, Prize } from '@entities/prizes/lib/types';

export enum ListPrizesActionTypes {
  LIST_PRIZES_ERROR = 'LIST_PRIZES_ERROR',
  LIST_PRIZES_REQUEST = 'LIST_PRIZES_REQUEST',
  LIST_PRIZES_RESET = 'LIST_PRIZES_RESET',
  LIST_PRIZES_SUCCESS = 'LIST_PRIZES_SUCCESS',
  LIST_PRIZES_SORT = 'LIST_PRIZES_SORT',
}

export type ListPrizesAction =
  | ListPrizesRequest
  | ListPrizesSuccess
  | ListPrizesError
  | ListPrizesReset;

export interface ListPrizesRequest {
  type: ListPrizesActionTypes.LIST_PRIZES_REQUEST;
  searchString: string;
  currentPage: number;
  sort?: ListPrizeSort;
  size?: number;
  locale?: String;
}

export const DEFAULT_PRIZES_SORT = {
  field: 'ALL',
};

export interface ListPrizesSuccess {
  type: ListPrizesActionTypes.LIST_PRIZES_SUCCESS;
  prizes: Prize[];
  total: number;
}

export interface ListPrizesError {
  type: ListPrizesActionTypes.LIST_PRIZES_ERROR;
  error: Error;
}

export interface ListPrizesReset {
  type: ListPrizesActionTypes.LIST_PRIZES_RESET;
}

export function listPrizes(
  searchString: string,
  currentPage: number = 0,
  sort = DEFAULT_PRIZES_SORT,
): ListPrizesRequest {
  return {
    type: ListPrizesActionTypes.LIST_PRIZES_REQUEST,
    searchString,
    currentPage,
    sort,
  };
}

export function listPrizesSucceeded(
  total: number,
  prizes: Prize[],
): ListPrizesSuccess {
  return {
    type: ListPrizesActionTypes.LIST_PRIZES_SUCCESS,
    prizes,
    total,
  };
}

export function listPrizesFailed(error: Error): ListPrizesError {
  return {
    type: ListPrizesActionTypes.LIST_PRIZES_ERROR,
    error,
  };
}

export function listPrizesReset(): ListPrizesReset {
  return {
    type: ListPrizesActionTypes.LIST_PRIZES_RESET,
  };
}
