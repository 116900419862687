/* External dependencies */
import { gql as gqlApollo } from '@apollo/client';
import gql from 'graphql-tag';

export const listCategoriesQuery = gql`
  query listCategories($input: ListCategoriesInput!) {
    listCategories(input: $input) {
      total
      categories {
        icon {
          url
        }
        id
        title
        title_ru
        searchTitle_ru
        description
      }
    }
  }
`;

export const listCategoriesWithApolloClient = gqlApollo`
  query listCategories($input: ListCategoriesInput!) {
    listCategories(input: $input) {
      total
      categories {
        icon {
          url
        }
        id
        title
        title_ru
        searchTitle_ru
        description
      }
    }
  }
`;
