export interface ArticleContent {
  type: string;
  data: string;
}

export enum ArticleType {
  CLIPPED_CARD = 'CLIPPED_CARD',
  OVERLAYED_CARD = 'OVERLAYED_CARD',
}

export enum ArticleCategory {
  MedCuts = 'MedCuts',
  Interview = 'Interview',
  MedCast = 'MedCast',
  MedShare = 'MedShare',
}

export interface MediaFile {
  imageUrl: string;
  fullVideoUrl: string;
}

export interface Article {
  articleType?: ArticleType;
  author?: { id: string };
  category?: string;
  clinic?: { id: string };
  contents?: [ArticleContent];
  heroMedia?: MediaFile;
  id?: string;
  physician?: { id: string };
  pharmacist?: { id: string };
  synopsis?: string;
  title?: string;
  readTimeMinute?: number;
  createDate?: string;
}

export interface CreateArticleInput {
  articleType: ArticleType;
  author?: ArticleAuthorInput;
  category?: string;
  clinic?: ArticleClinicInput;
  contents?: [ContentInput];
  heroMedia?: MediaFileInput;
  physician?: ArticlePhysicianInput;
  pharmacist?: ArticlePharmacistInput;
  readTimeMinute?: number;
  synopsis?: string;
  title: string;
}

export interface ListArticlesInput {
  from?: number;
  query?: string;
  size?: number;
}

export interface ListArticlesFilter {
  category: string; // не работает, просто заглушка
}

export interface UpdateArticleInput {
  articleType?: ArticleType;
  author?: ArticleAuthorInput;
  category?: String;
  clinic?: ArticleClinicInput;
  contents?: [ContentInput];
  heroMedia?: MediaFileInput;
  id: string;
  physician?: ArticlePhysicianInput;
  pharmacist?: ArticlePharmacistInput;
  synopsis?: String;
  readTimeMinute?: string;
  title?: String;
}

interface ArticleAuthorInput {
  id: string;
}

interface ArticleClinicInput {
  id: string;
}

interface ArticlePhysicianInput {
  id: string;
}

interface ArticlePharmacistInput {
  id: string;
}

interface ContentInput {
  type: ContentType;
  data: string;
}

export enum ContentType {
  IMAGE = 'IMAGE',
  MARKDOWN = 'MARKDOWN',
  YOUTUBE = 'YOUTUBE',
}

interface MediaFileInput {
  imageUrl?: string;
  fullVideoUrl?: string;
}

export interface UpdateArticleItemInputFields {
  [key: string]: any;
}
