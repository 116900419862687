// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { UserAction } from '@entities/user-actions/types';
import {
  DEFAULT_LIST_USER_ACTIONS_FILTER,
  ListUserActionsActionTypes,
  ListUserActionsTypes,
} from './actions';

export interface UserActionsState {
  userActions: UserAction[];
  currentPage?: number;
  error?: Error;
  filter: any;
  isSuccess?: boolean;
  loading: boolean;
  total: number;
  searchString?: string;
  size?: number;
}

export const DEFAULT_USER_ACTIONS_SIZE = 20;

export const initalUserActionsState: UserActionsState = {
  currentPage: 1,
  total: 0,
  loading: false,
  userActions: [],
  size: DEFAULT_USER_ACTIONS_SIZE,
  filter: DEFAULT_LIST_USER_ACTIONS_FILTER,
};

export default function userActionsReducer(
  state = initalUserActionsState,
  action: ListUserActionsTypes,
) {
  switch (action.type) {
    case ListUserActionsActionTypes.LIST_USER_ACTIONS_REQUEST:
      const { currentPage, searchString, filter } = action;

      return update(state, {
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
      });

    case ListUserActionsActionTypes.LIST_USER_ACTIONS_SUCCESS:
      const { total, userActions } = action;

      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        total: { $set: total },
        userActions: { $set: userActions },
      });

    case ListUserActionsActionTypes.LIST_USER_ACTIONS_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case ListUserActionsActionTypes.RESET_LIST_USER_ACTIONS:
      return state;

    default:
      return state;
  }
}
