// Local dependencies
import { Category, ListCategoriesFilter } from '../model/models';

export enum ListCategoriesActionTypes {
  LIST_CATEGORIES_REQUEST = 'LIST_CATEGORIES_REQUEST',
  LIST_CATEGORIES_ERROR = 'LIST_CATEGORIES_ERROR',
  LIST_CATEGORIES_SUCCESS = 'LIST_CATEGORIES_SUCCESS',
  RESET_LIST_CATEGORIES = 'RESET_LIST_CATEGORIES',
}

export type ListCategoriesTypes =
  | ListCategories
  | ListCategoriesSucceeded
  | ListCategoriesError
  | ResetListCategories;

export interface ListCategories {
  currentPage: number;
  filter?: ListCategoriesFilter;
  searchString: string;
  type: ListCategoriesActionTypes.LIST_CATEGORIES_REQUEST;
}

export interface ListCategoriesSucceeded {
  categories: Category[];
  total: number;
  type: ListCategoriesActionTypes.LIST_CATEGORIES_SUCCESS;
}

export interface ListCategoriesError {
  error: Error;
  type: ListCategoriesActionTypes.LIST_CATEGORIES_ERROR;
}

export interface ResetListCategories {
  type: ListCategoriesActionTypes.RESET_LIST_CATEGORIES;
}

export function listCategories(
  searchString: string = '',
  currentPage: number = 1,
  filter?: ListCategoriesFilter,
): ListCategories {
  return {
    currentPage,
    filter,
    searchString,
    type: ListCategoriesActionTypes.LIST_CATEGORIES_REQUEST,
  };
}

export function listCategoriesSucceeded(
  total: number,
  categories: Category[],
): ListCategoriesSucceeded {
  return {
    categories,
    total,
    type: ListCategoriesActionTypes.LIST_CATEGORIES_SUCCESS,
  };
}

export function listCategoriesError(error: Error): ListCategoriesError {
  return {
    error,
    type: ListCategoriesActionTypes.LIST_CATEGORIES_ERROR,
  };
}

export function resetListCategories(): ResetListCategories {
  return {
    type: ListCategoriesActionTypes.RESET_LIST_CATEGORIES,
  };
}
