/* Local dependencies */
import { Prize } from '@entities/prizes/lib/types';
import { User } from '../users/lib/types';

export enum ActionType {
  PURCHASE = 'Purchase',
  WATCHING_VIDEO = 'WatchingVideo',
  LIKE = 'Like',
  DISLIKE = 'Dislike',
  EXCHANGEPOINTS = 'ExchangePoints',
}

export enum PaymentStatus {
  APPROVED = 'APPROVED',
  IN_REVIEW = 'IN_REVIEW',
  CANCELED = 'CANCELED',
}

export type UserActionEntity = Purchase | WatchingVideo | ExchangingPoints;

export interface UserAction {
  data: UserActionEntity;
  eventDate: number;
  id: string;
  status?: UserActionStatus;
  type: ActionType;
  userId?: string;
  user?: User;
  updatedDate?: string;
  operatorId?: string;
  statusMessage?: string;
}

export enum UserActionStatus {
  APPROVED = 'APPROVED',
  IN_REVIEW = 'IN_REVIEW',
  CANCELED = 'CANCELED',
}

enum AppConfigsType {
  PurchaseMethod,
}

export interface PurchaseMethod {
  id: string;
  accountName: string;
  accountNumber: string;
  logo: string;
  name: string;
  __typename: AppConfigsType;
}

export interface Purchase {
  parentId: string;
  receiptFile: string;
  amount: number;
  purchaseMethod: PurchaseMethod;
  __typename: string;
}

export interface WatchingVideo {
  parentId: string;
  position: number;
  isCompleted: boolean;
  __typename: string;
}

export interface ExchangingPoints {
  destinationAddress: string;
  firstName: string;
  lastName: string;
  phone: string;
  prizes: Prize[];
  totalPoints: number;
}
