// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Article, ListArticlesFilter } from '@entities/articles/types';
import { DEFAULT_PAGE_SIZE } from '@shared/utils/helpers';
import {
  DEFAULT_LIST_ARTICLES_FILTER,
  ListArticlesAction,
  ListArticlesActionTypes,
} from './actions';

export interface ListArticlesState {
  articles: Article[];
  currentPage: number;
  filter: ListArticlesFilter;
  from?: number;
  error?: Error;
  loading: boolean;
  searchString: string;
  size: number;
  total: number;
}

export const ListArticlesInititalState: ListArticlesState = {
  articles: [],
  currentPage: 1,
  filter: DEFAULT_LIST_ARTICLES_FILTER,
  loading: false,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
  total: 0,
};

export default function listArticlesReducer(
  state = ListArticlesInititalState,
  action: ListArticlesAction,
) {
  switch (action.type) {
    case ListArticlesActionTypes.LIST_ARTICLES_REQUEST:
      const { searchString, currentPage } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        searchString: { $set: searchString },
        currentPage: { $set: currentPage },
      });

    case ListArticlesActionTypes.LIST_ARTICLES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        articles: { $set: action.articles },
        total: { $set: action.total },
      });

    case ListArticlesActionTypes.LIST_ARTICLES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListArticlesActionTypes.LIST_ARTICLES_CHANGE_CURRENT_FILTER:
      return update(state, {
        filter: {
          $set: update(state.filter, {
            category: { $set: action.filter },
          }),
        },
      });

    case ListArticlesActionTypes.LIST_ARTICLES_CHANGE_SEARCH_STRING:
      return update(state, {
        searchString: { $set: action.searchString },
      });

    case ListArticlesActionTypes.LIST_ARTICLES_RESET:
      return ListArticlesInititalState;

    default:
      return state;
  }
}
