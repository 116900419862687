// External dependencies
import * as AWSCognito from 'amazon-cognito-identity-js';

// Local dependencies
import {
  LoginRequest,
  SendVerificationCodeRequest,
  SessionUser,
  initClientSucceeded,
  setUserSession,
} from '@entities/authentication/redux/actions';
import { COGNITO_USER_POOL_DATA } from './cognito';
import CustomCognitoUserSession from './cognitoUserSession';

interface Token {
  payload: SessionUser;
  jwtToken: string;
}

interface RefreshToken {
  token: string;
}
export interface Session {
  accessToken?: Token;
  email: string;
  email_verified: Boolean;
  family_name: string;
  given_name: string;
  idToken?: Token;
  newPasswordRequired?: Boolean;
  phone_number: Number;
  phone_number_verified: Boolean;
  refreshToken?: RefreshToken;
}

const asyncInitiateAuthUser = (
  cognitoUser,
  cognitoAuthenticationDetails,
): Promise<CustomCognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
    cognitoUser.initiateAuth(cognitoAuthenticationDetails, {
      onSuccess: resolve,
      // TODO: handle a case when a user does not exist. Show an error
      // saying that permission is denied to the Admin panel.
      onFailure: reject,
      customChallenge: resolve,
    });
  });
};

export async function customAuthSignIn({ username }: LoginRequest) {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(
    COGNITO_USER_POOL_DATA,
  );
  const userData = { Username: username, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  const cognitoAuthenticationDetails = new AWSCognito.AuthenticationDetails({
    Username: username,
  });

  try {
    await asyncInitiateAuthUser(cognitoUser, cognitoAuthenticationDetails);

    return setUserSession(cognitoUser.Session);
  } catch (err) {
    throw err;
  }
}

const asyncSendCustomChallengeAnswer = (
  cognitoUser,
  verificationCode,
): Promise<CustomCognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.sendCustomChallengeAnswer(verificationCode, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
};

export async function sendVerificationCode({
  currentUserSession,
  verificationCode,
  username,
}: SendVerificationCodeRequest) {
  const cognitoUserPool = new AWSCognito.CognitoUserPool(
    COGNITO_USER_POOL_DATA,
  );
  const userData = { Username: username, Pool: cognitoUserPool };
  const cognitoUser = new AWSCognito.CognitoUser(userData);

  cognitoUser.Session = currentUserSession;

  try {
    const session: CustomCognitoUserSession =
      await asyncSendCustomChallengeAnswer(cognitoUser, verificationCode);

    return initClientSucceeded(session);
  } catch (err) {
    throw err;
  }
}
