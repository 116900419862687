// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Prize, Status } from '../../lib/types';
import { CreatePrizeAction, CreatePrizeActionTypes } from './actions';
import validateCreatePrizeFields from './validation';

export interface CreatePrizeState {
  error?: Error;
  loading: boolean;
  prize: Prize;
  isCreatePrizeFormOpen: boolean;
  isConfirmationPopupOpen: boolean;
  isSuccess?: boolean;
  nameError?: Error;
  avatarError?: Error;
  requiredPointsError?: Error;
  orderNumberError?: Error;
  amountError?: Error;
}

export const initialCreatePrizeState: CreatePrizeState = {
  loading: false,
  prize: {
    name: '',
    avatar: [{ url: '' }],
    requiredPoints: null,
    orderNumber: null,
    amount: null,
  },
  isCreatePrizeFormOpen: false,
  isConfirmationPopupOpen: false,
  isSuccess: false,
};

export default function createPrizeReducer(
  state = initialCreatePrizeState,
  action: CreatePrizeAction,
) {
  switch (action.type) {
    case CreatePrizeActionTypes.CREATE_PRIZE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreatePrizeActionTypes.CREATE_PRIZE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        prize: { $set: action.prize },
      });

    case CreatePrizeActionTypes.CREATE_PRIZE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case CreatePrizeActionTypes.CREATE_PRIZE_RESET:
      return initialCreatePrizeState;

    case CreatePrizeActionTypes.CREATE_PRIZE_OPEN_CLOSE_FORM:
      return update(state, {
        isCreatePrizeFormOpen: { $set: action.value },
      });

    case CreatePrizeActionTypes.CREATE_PRIZE_OPEN_CONFIRMATION_POPUP:
      return update(state, {
        isConfirmationPopupOpen: { $set: action.value },
      });

    case CreatePrizeActionTypes.CREATE_PRIZE_UPDATE_FIELDS:
      return validateCreatePrizeFields(state, action.prize);

    default:
      return state;
  }
}
