// External dependencies
import gql from 'graphql-tag';

export const updateUserActionForManagerMutation = gql`
  mutation updateUserActionForManager(
    $input: UpdateUserActionForManagerInput!
  ) {
    updateUserActionForManager(input: $input) {
      eventDate
      id
      status
      type
      statusMessage
    }
  }
`;
