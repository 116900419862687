// External dependencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '@app/clients/medcheck';
import {
  UpdateUserActionForManager,
  updateUserActionForManagerFailed,
  updateUserActionForManagerSucceeded,
  UpdateUserActionForManagerTypes,
} from './actions';
import { updateUserActionForManagerMutation } from './mutation';

export default function updateUserActionForManagerEpic(action$) {
  return action$.pipe(
    filter(
      (action: UpdateUserActionForManager) =>
        action.type ===
        UpdateUserActionForManagerTypes.UPDATE_USER_ACTION_FOR_MANAGER_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: UpdateUserActionForManager) =>
      updateUserActionForManager(action)
        .then((userAction) => updateUserActionForManagerSucceeded(userAction))
        .catch((error) => updateUserActionForManagerFailed(error)),
    ),
  );
}

export async function updateUserActionForManager(action): Promise<any> {
  const graphQLClient = await getClient();
  const { updateUserActionForManagerInput } = action;

  const {
    data: { updateUserActionForManager },
  } = await graphQLClient.mutate({
    mutation: updateUserActionForManagerMutation,
    variables: {
      input: updateUserActionForManagerInput,
    },
  });

  return updateUserActionForManager;
}
