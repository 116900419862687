//Local depedencies
import { UpdateUser, UpdateUserAction, UpdateUserActionsTypes } from './action';

export interface UpdateUserState {
  error?: Error;
  loading: boolean;
  user?: UpdateUser;
  isSuccess: boolean;
}

const initialStateUpdateUser: UpdateUserState = {
  loading: false,
  isSuccess: false,
};

export default function updateUser(
  state = initialStateUpdateUser,
  action: UpdateUserAction,
) {
  switch (action.type) {
    case UpdateUserActionsTypes.UPDATE_USER_REQUEST: {
      return {
        loading: true,
        isSuccess: false,
      };
    }

    case UpdateUserActionsTypes.UPDATE_USER_SUCCESS: {
      return {
        loading: false,
        isSuccess: true,
        user: action.user,
      };
    }

    case UpdateUserActionsTypes.UPDATE_USER_ERROR: {
      return {
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    }

    case UpdateUserActionsTypes.UPDATE_USER_RESET: {
      return {
        loading: false,
        isSuccess: false,
      };
    }

    default: {
      return state;
    }
  }
}
