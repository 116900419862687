/* External dependencies */
import gql from 'graphql-tag';

export const getCourseQuery = gql`
  query getCourse($input: GetCourseInput!) {
    getCourse(input: $input) {
      id
      ageRestrictions
      courseItems {
        id
        currentWatchTime
        description {
          en
          ru
          ky
        }
        isLiked
        isWatched
        item {
          previewImage {
            en
            ru
            ky
          }
          url
          title {
            en
            ru
            ky
          }
          description {
            en
            ru
            ky
          }
          duration
        }
        likesCount
        name {
          en
          ru
          ky
        }
        parentId
        postDate
        reviews {
          id
          parentId
          message
          rating
          likeCount
          likeState
          repliesCount
          postDate
        }
        reviewsCount
        status
        title {
          en
          ru
          ky
        }
        __typename
      }
      coursePrice {
        isFrom
        value
        currency
      }
      courseItemsCount
      description {
        en
        ru
        ky
      }
      image {
        en
        ru
        ky
      }
      isLiked
      purchaseStatus
      lastReview {
        id
        parentId
        message
        rating
        likeCount
        likeState
        repliesCount
        postDate
      }
      likesCount
      name {
        en
        ru
        ky
      }
      parentId
      physicianId
      postDate
      reviews {
        id
        parentId
        message
        rating
        likeCount
        likeState
        repliesCount
        postDate
      }
      reviewsCount
      status
      title {
        en
        ru
        ky
      }
      totalDuration
      video {
        en {
          previewImage
          url
          title
          description
          duration
        }
        ru {
          previewImage
          url
          title
          description
          duration
        }
        ky {
          previewImage
          url
          title
          description
          duration
        }
      }
      postDate
      __typename
    }
  }
`;
