/* External dependencies */
import * as AWSCognito from 'amazon-cognito-identity-js';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import AWSAppSyncClient from 'aws-appsync';
import { navigate } from 'gatsby';
import update from 'immutability-helper';

/* Local dependencies */
import { Session, getSessionUser } from '@app/clients/cognito';
import { setupClient } from '@app/clients/medcheck';
import { updateOneSignalExternalUserId } from '../../notification/connectOneSignal';
import { LoginAction, LoginActionTypes, SessionUser } from './actions';

export type LoginState = {
  // `client` is used by the ApolloWrapper to pass down to the `ApolloProvider`.
  client?: AWSAppSyncClient<NormalizedCacheObject>;
  currentUser?: any;
  currentUserSession?: any;
  error?: Error;
  initializing?: boolean;
  loading?: boolean;
  userAttributes?: Session;
  username?: string;
  verificationCode?: string;
};

const initialLoginState: LoginState = {
  initializing: true,
  client: null,
};

export default function loginReducer(
  state = initialLoginState,
  action: LoginAction,
) {
  switch (action.type) {
    case LoginActionTypes.LOGOUT_REQUEST:
      updateOneSignalExternalUserId();

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case LoginActionTypes.LOGOUT_SUCCESS:
      navigate('/');

      return update(state, {
        loading: { $set: false },
        $unset: ['client'],
      });

    case LoginActionTypes.LOGOUT_ERROR:
      const { error } = action;

      return update(state, {
        error: { $set: error },
        loading: { $set: false },
      });

    case LoginActionTypes.INIT_CLIENT_FAILED:
      return update(state, {
        initializing: { $set: false },
        client: { $set: undefined },
      });

    case LoginActionTypes.INIT_CLIENT_SUCCEEDED:
      const { session } = action;

      const idToken = session.getIdToken().getJwtToken();
      const idTokenObj = new AWSCognito.CognitoIdToken({ IdToken: idToken });
      const currentUser = getSessionUser(idTokenObj) as SessionUser;
      const client = setupClient(session);

      updateOneSignalExternalUserId(currentUser.sub);

      return update(state, {
        client: { $set: client },
        currentUser: { $set: currentUser },
        initializing: { $set: false },
        loading: { $set: false },
      });

    case LoginActionTypes.LOGIN_REQUEST:
      const { password, username } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        password: { $set: password },
        username: { $set: username },
      });

    case LoginActionTypes.LOGIN_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case LoginActionTypes.SEND_VERIFICATION_CODE_REQUEST:
      return update(state, {
        verificationCode: { $set: action.verificationCode },
        loading: { $set: true },
      });

    case LoginActionTypes.SET_USER_SESSION:
      navigate('verify-code');

      return update(state, {
        currentUserSession: { $set: action.currentUserSession },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
