// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { EntryType, FilterEntryType } from '@entities/users/lib/types';
import { DoctorAndClinicActionTypes, DoctorAndClinicTypes } from './actions';

export interface DoctorsAndClinicsState {
  filter: string;
  searchString: string;
  currentPage: number;
  activeTab: string;
}

export const doctorsAndClinicsInitialState: DoctorsAndClinicsState = {
  filter: EntryType.SPECIALTIES,
  searchString: '',
  currentPage: 1,
  activeTab: FilterEntryType.SPECIALTIES,
};

export default function doctorsAndClinicsReducer(
  state = doctorsAndClinicsInitialState,
  action: DoctorAndClinicTypes,
) {
  switch (action.type) {
    case DoctorAndClinicActionTypes.CHANGE_USER_FILTER:
      return update(state, {
        filter: { $set: action.filter },
      });

    case DoctorAndClinicActionTypes.ADD_SEARCH_STRING:
      return update(state, {
        searchString: { $set: action.searchString },
      });

    case DoctorAndClinicActionTypes.ADD_CURRENT_PAGE:
      return update(state, {
        currentPage: { $set: action.currentPage },
      });

    case DoctorAndClinicActionTypes.ADD_ACTIVE_TAB:
      return update(state, {
        activeTab: { $set: action.activeTab },
      });

    default: {
      return state;
    }
  }
}
