// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Course } from '../../types';
import { CreateCourseAction, CreateCourseActionTypes } from './actions';
import validateCreateCourseFields from './validation';

export interface CreateCourseState {
  error?: Error;
  loading: boolean;
  isSuccess: boolean;
  course?: Course;
  errors?: any;
  nameRuError?: Error;
  nameEnError?: Error;
  nameKyError?: Error;
  descriptionEnError?: Error;
  descriptionRuError?: Error;
  descriptionKyError?: Error;
  imageEnError?: Error;
  imageRuError?: Error;
  imageKyError?: Error;
  videoEnError?: Error;
  videoRuError?: Error;
  videoKyError?: Error;
  ageRestrictionsError?: Error;
  coursePriceError?: Error;
}

export const initialStateCreateCourse: CreateCourseState = {
  loading: false,
  isSuccess: false,
  course: {
    image: {
      en: '',
      ru: '',
      ky: '',
    },
    name: {
      en: '',
      ru: '',
      ky: '',
    },
    video: {
      ru: {
        previewImage: '',
        url: '',
      },
      en: {
        previewImage: '',
        url: '',
      },
      ky: {
        previewImage: '',
        url: '',
      },
    },
    title: {
      en: '',
      ru: '',
      ky: '',
    },
    description: {
      en: '',
      ru: '',
      ky: '',
    },
    ageRestrictions: '',
    coursePrice: {
      value: '',
      currency: 'KGS',
    },
  },
};

export default function createCourseReducer(
  state = initialStateCreateCourse,
  action: CreateCourseAction,
) {
  switch (action.type) {
    case CreateCourseActionTypes.CREATE_COURSE_REQUEST:
      return update(state, {
        loading: { $set: true },
        isSuccess: { $set: false },
      });

    case CreateCourseActionTypes.CREATE_COURSE_SUCCESS:
      const { course } = action;
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        course: { $set: course },
      });

    case CreateCourseActionTypes.CREATE_COURSE_ERROR:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: action.error },
      });

    case CreateCourseActionTypes.CREATE_COURSE_UPDATE_FIELDS:
      return validateCreateCourseFields(state, action.course);

    case CreateCourseActionTypes.RESET_CREATE_COURSE_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case CreateCourseActionTypes.RESET_CREATE_COURSE:
      return initialStateCreateCourse;

    default:
      return state;
  }
}
