// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { EntryUnion } from '../../lib/types';
import { GetUserAction, GetUserActionTypes } from './actions';

export interface GetUserState {
  error?: Error;
  loading: boolean;
  user?: EntryUnion;
  isSuccess: boolean;
}

export const initialGetUserState: GetUserState = {
  loading: false,
  isSuccess: false,
};

export default function getUserReducer(
  state = initialGetUserState,
  action: GetUserAction,
) {
  switch (action.type) {
    case GetUserActionTypes.GET_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case GetUserActionTypes.GET_USER_SUCCESS:
      return update(state, {
        user: { $set: action.user },
        loading: { $set: false },
        isSuccess: { $set: true },
      });

    case GetUserActionTypes.GET_USER_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetUserActionTypes.GET_USER_RESET:
      return initialGetUserState;

    default:
      return state;
  }
}
