// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { statusMessageMaxLength } from '@entities/user-actions/utils/helpers';
import { MaxLength100Exception } from '@shared/utils/exceptions';
import { UpdateUserActionState } from './reducer';

export function validateUpdateUserAction(
  state: UpdateUserActionState,
  updates: any,
) {
  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  const { statusMessage } = updates;

  if (updates.hasOwnProperty('statusMessage')) {
    if (statusMessage.length >= statusMessageMaxLength) {
      change.statusMessageError = { $set: new MaxLength100Exception() };
    } else {
      change['$unset'] = ['statusMessageError'];
    }

    return update(state, {
      ...change,
      statusMessage: { $set: statusMessage },
    });
  }

  return state;
}
