// External dependencies
import update from 'immutability-helper';

// Local dependencies
import isValidUrl from '@shared/utils/isValidUrl';
import {
  CategoryLengthRequiredException,
  DataLengthRequiredExpection,
  TitleLengthRequiredException,
  ValidFormatForLinkRequiredException,
} from '../../lib/exceptions';
import {
  ContentType,
  UpdateArticleItemInputFields,
} from '@entities/articles/types';
import { GetArticleState } from './reducer';
import { UpdateArticleUpdateValue } from './actions';

export default function validateUpdateArticleFields(
  state: GetArticleState,
  value: UpdateArticleItemInputFields,
) {
  const inputName = Object.keys(value)[0];

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  switch (inputName) {
    case 'category':
      if (value[inputName].length < 3) {
        change.categoryError = { $set: new CategoryLengthRequiredException() };
      } else {
        delete state['categoryError'];
      }

      return update(state, {
        ...change,
        article: {
          category: { $set: value[inputName] },
        },
        editedArticle: {
          category: { $set: value[inputName] },
        },
      });

    case 'data':
      if (value[inputName].length < 100) {
        change.dataError = { $set: new DataLengthRequiredExpection() };
      } else {
        delete state['dataError'];
      }

      return update(state, {
        ...change,
        article: {
          contents: [
            {
              data: { $set: value[inputName] },
            },
          ],
        },
        editedArticle: {
          $set: {
            ...(state.editedArticle || {}),
            contents: [
              {
                data: value[inputName],
                type: ContentType.MARKDOWN,
              },
            ],
          },
        },
      });

    case 'imageUrl':
      return update(state, {
        ...change,
        article: {
          heroMedia: {
            imageUrl: { $set: value[inputName] },
          },
        },
        editedArticle: {
          $set: {
            ...(state.editedArticle || {}),
            heroMedia: {
              ...((state?.editedArticle && state?.editedArticle?.heroMedia) ||
                {}),
              fullVideoUrl: state?.article?.heroMedia?.fullVideoUrl || '',
              imageUrl: value[inputName],
            },
          },
        },
      });

    case 'articleType':
      return update(state, {
        ...change,
        article: {
          articleType: { $set: value[inputName] },
        },
        editedArticle: {
          articleType: { $set: value[inputName] },
        },
      });

    case 'fullVideoUrl':
      if (!isValidUrl(value[inputName])) {
        change.fullVideoUrlError = {
          $set: new ValidFormatForLinkRequiredException(),
        };
      } else {
        delete state['fullVideoUrlError'];
      }

      return update(state, {
        ...change,
        article: {
          heroMedia: {
            fullVideoUrl: { $set: value[inputName] },
          },
        },
        editedArticle: {
          $set: {
            ...(state.editedArticle || {}),
            heroMedia: {
              ...((state?.editedArticle && state?.editedArticle?.heroMedia) ||
                {}),
              imageUrl: state?.article?.heroMedia?.imageUrl || '',
              fullVideoUrl: value[inputName],
            },
          },
        },
      });

    case 'synopsis':
      if (value[inputName].length < 10) {
        change.synopsisError = { $set: new TitleLengthRequiredException() };
      } else {
        delete state['synopsisError'];
      }

      return update(state, {
        ...change,
        article: {
          synopsis: { $set: value[inputName] },
        },
        editedArticle: {
          synopsis: { $set: value[inputName] },
        },
      });

    case 'title':
      if (value[inputName].length < 10) {
        change.titleError = { $set: new TitleLengthRequiredException() };
      } else {
        delete state['titleError'];
      }

      return update(state, {
        ...change,
        article: {
          title: { $set: value[inputName] },
        },
        editedArticle: {
          title: { $set: value[inputName] },
        },
      });

    default:
      return state;
  }
}
