export interface ProAccount {
  id: string;
  avatar?: string;
  consultationPrice?: ConsultationPrice;
  contacts?: Contacts;
  email?: string;
  phone: string;
  phonesExtra?: string[];
  profileCompletionRate?: number;
  servicesDescription?: string;
  services?: Service[];
  specialities?: string[];
  status?: UserStatus;
  url?: string;
  joinDate: string;
  __typename: EntryType;
}

export interface ConsultationPrice {
  isFrom: boolean;
  value: string;
  currency: string;
}

export interface Contacts {
  workPhone: SocialNetwork[];
  whatsApp: SocialNetwork;
  telegram: SocialNetwork;
  email: SocialNetwork;
  instagram: SocialNetwork;
  facebook: SocialNetwork;
  website: SocialNetwork;
}

export interface SocialNetwork {
  value: string;
}

export interface Service {
  id: string;
  name: string;
  price: ConsultationPrice;
  notes: string;
}

export enum ProAccountFilterStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  IN_REVIEW = 'IN_REVIEW',
}

export enum UserStatus {
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  INCOMPLETE = 'INCOMPLETE',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
}

export interface User {
  id: string;
  avatar: string;
  consultationPrice: Price;
  contacts: Contacts;
  email: string;
  phone: string;
  phonesExtra: [String];
  profileCompletionRate: number;
  personalIdentificationNumber: String;
  servicesDescription: String;
  services: [Service];
  specialities: [String];
  status: UserStatus;
  occupation: String;
  url: string;
  joinDate: string;
  __typename: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  workspaces: [Workplace];
  height: Anthropometry;
  birthDate: string;
  bloodType: BloodType;
  placeOfResidence: Address;
  weight: Anthropometry;
  gender: Gender;
  insurance: Insurance;
  emergencyContact: [TelephoneContact];
  proAccounts: any;
}

export interface Psysician {
  id: string;
  avatar: string;
  consultationPrice: Price;
  contacts: Contacts;
  email: string;
  phone: string;
  servicesDescription: String;
  services: [Service];
  specialities: [String];
  status: UserStatus;
  occupation: String;
  joinDate: string;
  __typename: EntryType;
  firstName: string;
  lastName: string;
  patronymic: String;
  education: String;
  experience: String;
  experienceSince: string;
  educations: [Education];
  level: String;
  workExperience: [WorkExperience];
  birthDate: string;
  placeOfResidence: Address;
  physicianCategory: PhysicianCategory;
}

export interface Clinic {
  id: string;
  avatar: string;
  consultationPrice: Price;
  contacts: Contacts;
  email: string;
  phone: string;
  services: [Service];
  status: UserStatus;
  joinDate: string;
  __typename: EntryType;
  clinicName: string;
  clinicAddress: Address;
  categories: [String];
}

export type EntryUnion = Clinic | Psysician | User;

export interface Price {
  isFrom?: boolean;
  value: string;
  currency: string;
}

export interface Contacts {
  workPhones: [WorkPhone];
  whatsApp: SocialNetwork;
  telegram: SocialNetwork;
  email: Email;
  instagram: SocialNetwork;
  facebook: SocialNetwork;
  website: SocialNetwork;
}

export interface WorkPhone {
  value: number | string;
}

export interface Service {
  name: string;
  price?: Price;
  notes?: string;
}

export interface Rating {
  avgRating: number;
  numReviews: number;
}

export enum EntryType {
  CLINIC = 'Clinic',
  HOSPITAL = 'Hospital',
  PHYSICIAN = 'Physician',
  PHARMACIST = 'Pharmacist',
  USER = 'USER',
  SPECIALTIES = 'specialties',
  DRUG = 'DRUG',
  COURSE = 'COURSE',
  COURSE_ITEM = 'COURSE_ITEM',
}

export enum FilterEntryType {
  CLINIC = 'Clinic',
  PHYSICIAN = 'Physician',
  PHARMACIST = 'Pharmacist',
  SPECIALTIES = 'specialties',
  ALL = 'ALL',
}

export const filterEntryType = [
  FilterEntryType.CLINIC,
  FilterEntryType.PHARMACIST,
  FilterEntryType.PHYSICIAN,
  FilterEntryType.ALL,
];

export enum EntryStatus {
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  INCOMPLETE = 'INCOMPLETE',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
}

export interface Education {
  institution: string;
  studyPeriod: string;
  department: string;
}

export interface Workplace {
  clinicID: string;
  clinicName: string;
  clinicAddress: Address;
  phone?: number | string;
  services?: [Service];
}

export interface Address {
  address: string;
  addressExtra?: string;
  city: string;
  country: string;
  zipCode?: string;
  geoLocation?: string;
}

export interface WorkExperience {
  clinicID: string;
  clinicName: string;
  position: string;
  workPeriod: Period;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export interface Period {
  from: any;
  to?: any;
}

export interface Anthropometry {
  value: number;
  unit: string;
}

export interface TelephoneContact {
  name?: string;
  relation?: string;
  phone: number | string;
}

export enum BloodType {
  O_ONE_PLUS = 'O_ONE_PLUS',
  O_ONE_MINUS = 'O_ONE_MINUS',
  A_TWO_PLUS = 'A_TWO_PLUS',
  A_TWO_MINUS = 'A_TWO_MINUS',
  B_THREE_PLUS = 'B_THREE_PLUS',
  B_THREE_MINUS = 'B_THREE_MINUS',
  AB_FOUR_PLUS = 'AB_FOUR_PLUS',
  AB_FOUR_MINUS = 'AB_FOUR_MINUS',
}

export enum PhysicianCategory {
  FIRST_CATEGORY = 'FIRST_CATEGORY',
  SECOND_CATEGORY = 'SECOND_CATEGORY',
  HIGHEST_CATEGORY = 'HIGHEST_CATEGORY',
}

export interface Insurance {
  name?: string;
  status: number;
  startDate?: number | string;
  endDate?: number | string;
}

export interface SocialNetwork {
  value: string;
}

export interface Email {
  value: string;
}
