/*Local dependencies */
import CustomCognitoUserSession from '@app//clients/cognitoUserSession';
import { UserRoles } from '../../../shared/utils/roles';

export enum LoginActionTypes {
  INIT_CLIENT = 'INIT_CLIENT',
  INIT_CLIENT_FAILED = 'INIT_CLIENT_FAILED',
  INIT_CLIENT_SUCCEEDED = 'INIT_CLIENT_SUCCEEDED',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGOUT_ERROR = 'LOGOUT_ERROR',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  SEND_VERIFICATION_CODE_REQUEST = 'SEND_VERIFICATION_CODE_REQUEST',
  SET_USER_SESSION = 'SET_USER_SESSION',
}

export function initClient(): InitClient {
  return {
    type: LoginActionTypes.INIT_CLIENT,
  };
}

export function initClientFailed(): InitClientFailed {
  return {
    type: LoginActionTypes.INIT_CLIENT_FAILED,
  };
}

export function initClientSucceeded(
  session: CustomCognitoUserSession,
): InitClientSucceeded {
  return {
    type: LoginActionTypes.INIT_CLIENT_SUCCEEDED,
    session,
  };
}

export function logout(): LogoutRequest {
  return {
    type: LoginActionTypes.LOGOUT_REQUEST,
  };
}

export function login(username: string): LoginRequest {
  return {
    type: LoginActionTypes.LOGIN_REQUEST,
    username,
  };
}

export function loginFailed(error: Error): LoginError {
  return {
    type: LoginActionTypes.LOGIN_ERROR,
    error,
  };
}

export function logoutFailed(error: Error): LogoutError {
  return {
    type: LoginActionTypes.LOGOUT_ERROR,
    error,
  };
}

export function logoutSucceeded(): LogoutSuccess {
  return {
    type: LoginActionTypes.LOGOUT_SUCCESS,
  };
}

export interface LogoutRequest {
  type: LoginActionTypes.LOGOUT_REQUEST;
}

export interface LogoutError {
  type: LoginActionTypes.LOGOUT_ERROR;
  error: Error;
}

export interface LogoutSuccess {
  type: LoginActionTypes.LOGOUT_SUCCESS;
}

export interface LoginRequest {
  type: LoginActionTypes.LOGIN_REQUEST;
  username: string;
}

export interface LoginError {
  type: LoginActionTypes.LOGIN_ERROR;
  error: Error;
}

export interface LogoutError {
  type: LoginActionTypes.LOGOUT_ERROR;
  error: Error;
}

export function sendVerificationCodeRequest(
  username: string,
  verificationCode: string,
): SendVerificationCodeRequest {
  return {
    type: LoginActionTypes.SEND_VERIFICATION_CODE_REQUEST,
    username,
    verificationCode,
  };
}

export function setUserSession(currentUserSession: string): SetUserSession {
  return {
    type: LoginActionTypes.SET_USER_SESSION,
    currentUserSession,
  };
}

export interface InitClient {
  type: LoginActionTypes.INIT_CLIENT;
}

export interface InitClientFailed {
  type: LoginActionTypes.INIT_CLIENT_FAILED;
}

export interface InitClientSucceeded {
  type: LoginActionTypes.INIT_CLIENT_SUCCEEDED;
  session: CustomCognitoUserSession;
}

export interface SendVerificationCodeRequest {
  type: LoginActionTypes.SEND_VERIFICATION_CODE_REQUEST;
  currentUserSession?: string;
  username: string;
  verificationCode: string;
}

export interface SetUserSession {
  type: LoginActionTypes.SET_USER_SESSION;
  currentUserSession: string;
}

export type LoginAction =
  | InitClient
  | InitClientFailed
  | InitClientSucceeded
  | LoginRequest
  | LoginError
  | LogoutRequest
  | LogoutSuccess
  | SendVerificationCodeRequest
  | SetUserSession
  | LogoutError;

export interface SessionUser {
  aud;
  auth_time;
  'cognito:preferred_role': string;
  email;
  email_verified;
  event_id;
  exp;
  iat;
  iss;
  groups: UserRoles;
  jti;
  origin_jti;
  phone_number;
  phone_number_verified;
  roles: UserRoles;
  sub;
  token_use;
}
