// Local dependencies
import {
  Course,
  CreateCourseItemInputFields,
  UpdateCourseInput,
} from '../../types';

export enum GetCourseActionTypes {
  GET_COURSE_REQUEST = 'GET_COURSE_REQUEST',
  GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS',
  GET_COURSE_ERROR = 'GET_COURSE_ERROR',
  GET_COURSE_RESET = 'GET_COURSE_RESET',
  UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST',
  UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS',
  UPDATE_COURSE_ERROR = 'UPDATE_COURSE_ERROR',
  UPDATE_COURSE_RESET = 'UPDATE_COURSE_RESET',
  UPDATE_COURSE_ERROR_RESET = 'UPDATE_COURSE_ERROR_RESET',
  UPDATE_COURSE_UPDATE_VALUE = 'UPDATE_COURSE_UPDATE_VALUE',
}

export interface GetCourseRequest {
  type: GetCourseActionTypes.GET_COURSE_REQUEST;
  id: string;
}

export interface GetCourseSuccess {
  type: GetCourseActionTypes.GET_COURSE_SUCCESS;
  course: Course;
}

export interface GetCourseError {
  type: GetCourseActionTypes.GET_COURSE_ERROR;
  error: Error;
}

export interface GetCourseReset {
  type: GetCourseActionTypes.GET_COURSE_RESET;
}

export interface UpdateCourseRequest {
  type: GetCourseActionTypes.UPDATE_COURSE_REQUEST;
  updatedCourse: UpdateCourseInput;
}

export interface UpdateCourseSuccess {
  type: GetCourseActionTypes.UPDATE_COURSE_SUCCESS;
  course: Course;
}

export interface UpdateCourseError {
  type: GetCourseActionTypes.UPDATE_COURSE_ERROR;
  error: Error;
}

export interface ResetUpdateCourse {
  type: GetCourseActionTypes.UPDATE_COURSE_RESET;
}

export interface UpdateCourseErrorReset {
  type: GetCourseActionTypes.UPDATE_COURSE_ERROR_RESET;
}

export interface UpdateCourseUpdateValue {
  type: GetCourseActionTypes.UPDATE_COURSE_UPDATE_VALUE;
  updates: CreateCourseItemInputFields;
}

export type GetCourseAction =
  | GetCourseRequest
  | GetCourseSuccess
  | GetCourseError
  | GetCourseReset
  | UpdateCourseRequest
  | UpdateCourseSuccess
  | UpdateCourseError
  | ResetUpdateCourse
  | UpdateCourseErrorReset
  | UpdateCourseUpdateValue;

export function getCourse(id: string): GetCourseRequest {
  return {
    type: GetCourseActionTypes.GET_COURSE_REQUEST,
    id,
  };
}

export function getCourseSucceeded(course: Course): GetCourseSuccess {
  return {
    type: GetCourseActionTypes.GET_COURSE_SUCCESS,
    course,
  };
}

export function getCourseFailed(error: Error): GetCourseError {
  return {
    type: GetCourseActionTypes.GET_COURSE_ERROR,
    error,
  };
}

export function getCourseReset(): GetCourseReset {
  return {
    type: GetCourseActionTypes.GET_COURSE_RESET,
  };
}

export function updateCourseRequest(
  updatedCourse: UpdateCourseInput,
): UpdateCourseRequest {
  return {
    type: GetCourseActionTypes.UPDATE_COURSE_REQUEST,
    updatedCourse,
  };
}

export function updateCourseSucceeded(course: Course): UpdateCourseSuccess {
  return {
    type: GetCourseActionTypes.UPDATE_COURSE_SUCCESS,
    course,
  };
}

export function updateCourseFailed(error: Error): UpdateCourseError {
  return {
    type: GetCourseActionTypes.UPDATE_COURSE_ERROR,
    error,
  };
}

export function updateCourseReset(): ResetUpdateCourse {
  return {
    type: GetCourseActionTypes.UPDATE_COURSE_RESET,
  };
}

export function updateCourseErrorReset(): UpdateCourseErrorReset {
  return {
    type: GetCourseActionTypes.UPDATE_COURSE_ERROR_RESET,
  };
}

export function updateCourseUpdateField(
  updates: CreateCourseItemInputFields,
): UpdateCourseUpdateValue {
  return {
    type: GetCourseActionTypes.UPDATE_COURSE_UPDATE_VALUE,
    updates,
  };
}
