// External depedencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient, setAnonymousClient } from '@app/clients/medcheck';
import {
  ListArticlesAction,
  ListArticlesActionTypes,
  listArticlesFailed,
  ListArticlesRequest,
  listArticlesSucceeded,
  ListArticlesSuccess,
} from './actions';
import { listArticlesQuery } from './queries';

export default function listArticlesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListArticlesAction) =>
        action.type === ListArticlesActionTypes.LIST_ARTICLES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListArticlesRequest) => {
      return listArticles(action, state$?.value?.listArticles).catch(
        listArticlesFailed,
      );
    }),
  );
}

export async function listArticles(
  { searchString, currentPage }: ListArticlesRequest,
  state,
): Promise<ListArticlesSuccess> {
  const { size } = state;
  const graphQLClient = await setAnonymousClient();

  const {
    data: {
      listArticles: { articles, total },
    },
  } = await graphQLClient.query({
    query: listArticlesQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        size,
        query: searchString,
      },
    },
  });

  return listArticlesSucceeded(total, articles);
}
