// Local dependencies
import { BannerActionsTypes, BannerActionType } from './action';

export interface BannerState {
  isBanner: boolean;
}

export const BannerInitialState: BannerState = {
  isBanner: true,
};

export default function BannerReducer(
  state = BannerInitialState,
  action: BannerActionsTypes,
) {
  switch (action.type) {
    case BannerActionType.CLOSE_BANNER: {
      return {
        isBanner: false,
      };
    }

    default: {
      return state;
    }
  }
}
