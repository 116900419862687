/* External dependencies */
import gql from 'graphql-tag';

export const getUserQuery = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      ... on Physician {
        id
        email
        avatar
        servicesDescription
        consultationPrice {
          currency
          isFrom
          value
        }
        contacts {
          email {
            value
          }
          facebook {
            value
          }
          instagram {
            value
          }
          telegram {
            value
          }
          website {
            value
          }
          whatsApp {
            value
          }
          workPhone {
            value
          }
        }
        education
        educations {
          department
          institution
          studyPeriod {
            from
            to
          }
        }
        experience
        experienceSince
        firstName
        joinDate
        lastName
        bio
        level
        patronymic
        __typename
        phone
        physicianCategory
        slug
        placeOfResidence {
          address
          addressExtra
          city
          country
          geoLocation
          zipCode
        }
        services {
          name
          notes
          price {
            currency
            isFrom
            value
          }
        }
        workplaces {
          clinicName
          phone
          clinicAddress {
            city
            address
          }
          services {
            name
            price {
              isFrom
              value
              currency
            }
          }
        }
        specialities
        status
        rating {
          avgRating
          numReviews
        }
        emergencyContact {
          name
          phone
          relation
        }
        workExperience {
          clinicName
          position
          workPeriod {
            from
            to
          }
        }
      }
    }
  }
`;

export const getUserQueryClinic = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      ... on Clinic {
        id
        email
        __typename
        avatar
        categories
        clinicAddress {
          address
          addressExtra
          city
          country
          geoLocation
          zipCode
        }
        clinicName
        consultationPrice {
          currency
          isFrom
          value
        }
        contacts {
          email {
            value
          }
          instagram {
            value
          }
          telegram {
            value
          }
          website {
            value
          }
          whatsApp {
            value
          }
          workPhone {
            value
          }
        }
        joinDate
        phone
        services {
          name
          notes
          price {
            currency
            isFrom
            value
          }
        }
        status
        specialities
      }
    }
  }
`;

export const getPharmacistQuery = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      ... on Pharmacist {
        id
        email
        avatar
        consultationPrice {
          currency
          isFrom
          value
        }
        contacts {
          email {
            value
          }
          facebook {
            value
          }
          instagram {
            value
          }
          telegram {
            value
          }
          website {
            value
          }
          whatsApp {
            value
          }
          workPhone {
            value
          }
        }
        education
        educations {
          department
          institution
        }
        firstName
        joinDate
        lastName
        patronymic
        phone
        services {
          name
          notes
          price {
            currency
            isFrom
            value
          }
        }
        specialities
        status
        workExperience {
          clinicName
          position
          workPeriod {
            from
            to
          }
        }
        currentPoints
        exchangedPoints
        visitPoints
      }
    }
  }
`;
