/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { CourseItem } from '../../types';
import {
  CreateCourseItemAction,
  CreateCourseItemActionTypes,
  CreateCourseItemRequest,
  createCourseItemFailed,
  createCourseItemSucceeded,
} from './actions';
import { createCourseItemMutation } from './mutation';
import { getClient } from '@app/clients/medcheck';

export default function createCourseItemEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateCourseItemAction) =>
        action.type === CreateCourseItemActionTypes.CREATE_COURSE_ITEM_REQUEST,
    ),
    switchMap((action: CreateCourseItemRequest) =>
      createCourseItem(action)
        .then((courseItem) => createCourseItemSucceeded(courseItem))
        .catch((error) => createCourseItemFailed(error)),
    ),
  );
}

export async function createCourseItem(
  action: CreateCourseItemRequest,
): Promise<CourseItem> {
  const graphQLClient = await getClient();
  const { courseItemInput } = action;

  const {
    data: { courseItem },
  } = await graphQLClient.mutate({
    mutation: createCourseItemMutation,
    variables: {
      input: courseItemInput,
    },
  });

  return courseItem as CourseItem;
}
