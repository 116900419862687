/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient, setAnonymousClient } from '@app/clients/medcheck';
import { Article } from '@entities/articles/types';
import {
  GetArticleAction,
  GetArticleActionTypes,
  getArticleFailed,
  GetArticleRequest,
  getArticleSucceeded,
  updateArticleFailed,
  UpdateArticleRequest,
  updateArticleSucceeded,
} from './actions';
import { updateArticleMutation } from './mutation';
import { getArticleQuery } from './queries';

export function getArticleEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetArticleAction) =>
        action.type === GetArticleActionTypes.GET_ARTICLE_REQUEST,
    ),
    switchMap((action: GetArticleRequest) =>
      getArticle(action).then(getArticleSucceeded).catch(getArticleFailed),
    ),
  );
}

export async function getArticle({
  id,
  slug,
}: GetArticleRequest): Promise<Article> {
  const graphQLClient = await setAnonymousClient();

  const {
    data: { getArticle },
  } = await graphQLClient.query({
    query: getArticleQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getArticle as Article;
}

export function updateArticleEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetArticleAction) =>
        action.type === GetArticleActionTypes.UPDATE_ARTICLE_REQUEST,
    ),
    switchMap((action: UpdateArticleRequest) =>
      updateArticle(action)
        .then(updateArticleSucceeded)
        .catch(updateArticleFailed),
    ),
  );
}

export async function updateArticle(
  action: UpdateArticleRequest,
): Promise<Article> {
  const graphQLClient = await getClient();
  const { updatedArticle } = action;

  const {
    data: { updateArticle },
  } = await graphQLClient.mutate({
    mutation: updateArticleMutation,
    variables: {
      input: updatedArticle,
    },
  });

  return updateArticle as Article;
}
