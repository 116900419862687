// External dependencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { Course } from '../../types';
import {
  CreateCourseAction,
  CreateCourseActionTypes,
  createCourseError,
  createCourseSuccess,
} from './actions';
import { createCourseMutation } from './mutation';
import { getClient } from '@app/clients/medcheck';

export default function createCourseEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateCourseAction) =>
        action.type === CreateCourseActionTypes.CREATE_COURSE_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: CreateCourseAction) =>
      createCourse(action)
        .then((course) => createCourseSuccess(course))
        .catch((error) => createCourseError(error)),
    ),
  );
}

export async function createCourse(action): Promise<Course> {
  const graphQLClient = await getClient();
  const { course } = action;

  const {
    data: { createCourse },
  } = await graphQLClient.mutate({
    mutation: createCourseMutation,
    variables: {
      input: course,
    },
  });

  return createCourse as Course;
}
