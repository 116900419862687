// External dependencies
import gql from 'graphql-tag';

export const listDrugsQuery = gql`
  query listDrugs($input: ListDrugsInput!) {
    listDrugs(input: $input) {
      total
      drugs {
        avatar
        categoryId
        companyId
        description_en
        description_ru
        description_kg
        description_ky
        name_en
        name_ru
        name_kg
        name_ky
        quizId
        title_en
        title_kg
        title_ky
        title_ru
        videoUrl
        postDate
        __typename
      }
    }
  }
`;
