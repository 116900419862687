/* Local dependencies */
import { EntryType } from '@entities/users/lib/types';

export function cleanPayload(payload: any) {
  Object.keys(payload).forEach((key: string) => {
    if (
      ['__typename'].includes(key) ||
      payload[key] === null ||
      payload[key] === ''
    ) {
      payload[key] = undefined;
    }

    if (typeof payload[key] === 'object') {
      payload[key] = cleanPayload(payload[key]);
    }
  });

  return payload;
}

export function isMobile(): boolean {
  return typeof window !== 'undefined' ? window.innerWidth < 650 : false;
}

export function getItemFromLocalStorage(key: string) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(key);
  }
}

export function setLocalStorage(key: string, value: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(key, value);
  }
}

export function extractWatchStringFromLink(link: string) {
  const wordToCut = 'watch?v=';
  const newLink = link.replace(wordToCut, '');

  return newLink;
}

export const DEFAULT_PAGE_SIZE = 20;

export function removeTypename(obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeTypename(item));
  }

  const newObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (key !== '__typename') {
      newObj[key] = removeTypename(value);
    }
  });

  return newObj;
}

export function truncateText(text: string, maxLength: number = 50) {
  if (!text) {
    return '';
  }

  if (text.length <= maxLength) {
    return text;
  }

  const truncatedDescription = text.slice(0, maxLength) + '...';
  return truncatedDescription;
}

export function extractVideoIdFromYoutubeVideoLink(youtubeLink: string) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;

  const match = youtubeLink.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

export const extractCountryAndCity = (user) => {
  switch (user?.__typename) {
    case EntryType.CLINIC:
      return getCountryAndCity(user?.clinicAddress);

    case EntryType.PHYSICIAN:
      const firstWorkplaceAddress = user?.workplaces?.[0]?.clinicAddress;
      return getCountryAndCity(firstWorkplaceAddress);

    default:
      return '';
  }
};

export const getCountryAndCity = (address) => {
  const { country, city } = address || {};

  const formattedCountry = country || '';
  const formattedCity = city || '';

  if (formattedCountry && formattedCity) {
    return `${formattedCountry}, ${formattedCity}`;
  }

  return formattedCountry || formattedCity;
};

export const extractMedServices = (user) => {
  switch (user?.__typename) {
    case EntryType.CLINIC:
      return getMedServices(user?.services);

    case EntryType.PHYSICIAN:
      const physicianWorkplaceServices = user?.workplaces?.[0]?.services || [];
      const physicianUserServices = user?.services || [];

      if (physicianWorkplaceServices.length > 0) {
        return getMedServices(physicianWorkplaceServices);
      }
      return getMedServices(physicianUserServices);

    default:
      return '';
  }
};

export const getMedServices = (services) => {
  if (services && services.length > 0) {
    return services?.map((service) => JSON.stringify(service?.name)).join(', ');
  }

  return '';
};

export const getFormattedSEOTitle = (
  user,
  specialist: string,
  countryAndCity: string,
) => {
  const isClinic = !user?.firstName_ru && !user?.lastName_ru;
  const formattedCountryAndCity = countryAndCity ? `| ${countryAndCity}` : '';
  const formattedSpecialist = specialist ? `| ${specialist}` : '';

  if (isClinic) {
    return `${user?.clinicName_ru} ${formattedCountryAndCity}`;
  } else {
    const formattedName = `${user?.firstName_ru} ${user?.lastName_ru}`;

    return `${formattedName} ${formattedSpecialist} ${formattedCountryAndCity}`;
  }
};
