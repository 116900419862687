// External dependencies
import validateCreateCourseFields from '@components/courses/createCourse/redux/validation';
import update from 'immutability-helper';
// Local dependencies
import { Course } from '../../types';
import { GetCourseAction, GetCourseActionTypes } from './action';

export interface GetCourseState {
  course?: Course;
  error?: Error;
  loading: boolean;
  isSuccess: boolean;
  nameRuError?: Error;
  nameEnError?: Error;
  nameKyError?: Error;
  descriptionEnError?: Error;
  descriptionRuError?: Error;
  descriptionKyError?: Error;
  imageEnError?: Error;
  imageRuError?: Error;
  imageKyError?: Error;
  videoEnError?: Error;
  videoRuError?: Error;
  videoKyError?: Error;
  ageRestrictionsError?: Error;
  coursePriceError?: Error;
}

export const initialGetCourseState: GetCourseState = {
  isSuccess: false,
  loading: false,
};

export default function getCourseReducer(
  state = initialGetCourseState,
  action: GetCourseAction,
) {
  switch (action.type) {
    case GetCourseActionTypes.GET_COURSE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetCourseActionTypes.GET_COURSE_SUCCESS:
      return update(state, {
        course: { $set: action.course },
        loading: { $set: false },
      });

    case GetCourseActionTypes.GET_COURSE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetCourseActionTypes.GET_COURSE_RESET:
      return initialGetCourseState;

    case GetCourseActionTypes.UPDATE_COURSE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetCourseActionTypes.UPDATE_COURSE_SUCCESS:
      const { course } = action;

      return update(state, {
        course: { $set: course },
        isSuccess: { $set: true },
        loading: { $set: false },
      });

    case GetCourseActionTypes.UPDATE_COURSE_ERROR:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: action.error },
      });

    case GetCourseActionTypes.UPDATE_COURSE_UPDATE_VALUE:
      return validateCreateCourseFields(state, action.updates);

    case GetCourseActionTypes.UPDATE_COURSE_ERROR_RESET:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
