/* External dependencies */
import {
  applyMiddleware,
  combineReducers,
  createStore as createReduxStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import createCourseEpic from '@components/courses/createCourse/redux/epic';
import createCourse from '@components/courses/createCourse/redux/reducer';
import createCourseItemEpic from '@components/courses/createCourseItem/redux/epic';
import createCourseItem from '@components/courses/createCourseItem/redux/reducer';
import {
  getCourseEpic,
  updateCourseEpic,
} from '@components/courses/getCourse/redux/epic';
import getCourseReducer from '@components/courses/getCourse/redux/reducer';
import listCoursesEpic from '@components/courses/listCourses/redux/epic';
import listCourses from '@components/courses/listCourses/redux/reducer';
import doctorsAndClinics from '@components/website/doctors-clinics/redux/reducer';
import banner from '@components/website/redux/reducer';
import createArticleEpic from '@entities/articles/redux/create-article-redux/epic';
import createArticle from '@entities/articles/redux/create-article-redux/reducer';
import {
  getArticleEpic,
  updateArticleEpic,
} from '@entities/articles/redux/get-update-article-redux/epic';
import getArticle from '@entities/articles/redux/get-update-article-redux/reducer';
import listArticlesEpic from '@entities/articles/redux/list-articles-redux/epic';
import listArticles from '@entities/articles/redux/list-articles-redux/reducer';
import {
  initClientEpic,
  loginEpic,
  logoutEpic,
  sendVerificationCodeEpic,
} from '@entities/authentication/redux/epics';
import login, { LoginState } from '@entities/authentication/redux/reducer';
import listDrugs from '@entities/drugs/redux/listDrug/reducer';
import listDrugsEpic from '@entities/drugs/redux/listDrug/epic';
import listCategoriesEpic from '@entities/medical-specialties/redux/epic';
import listCategories from '@entities/medical-specialties/redux/reducer';
import createPrizeEpic from '@entities/prizes/redux/createPrize/epic';
import createPrize from '@entities/prizes/redux/createPrize/reducer';
import listPrizesEpic from '@entities/prizes/redux/listPrizes/epic';
import listPrizes from '@entities/prizes/redux/listPrizes/reducer';
import listUserActionsEpic from '@entities/user-actions/redux/listUser-actions-redux/epic';
import listUserActions from '@entities/user-actions/redux/listUser-actions-redux/reducer';
import updateUserActionForManagerEpic from '@entities/user-actions/redux/update-useractions-redux/epic';
import updateUserActionForManager from '@entities/user-actions/redux/update-useractions-redux/reducer';
import listUsersEpic from '@entities/users/redux/list-users-redux/epics';
import users from '@entities/users/redux/list-users-redux/reducer';
import updateUserEpic from '@entities/users/redux/update-user-redux/epic';
import updateUser from '@entities/users/redux/update-user-redux/reducer';
import getUserEpic from '@entities/users/redux/user-details-redux/epics';
import getUser from '@entities/users/redux/user-details-redux/reducer';

export type ApplicationState = {
  login: LoginState;
};

const rootEpic = combineEpics(
  createArticleEpic,
  createCourseEpic,
  createCourseItemEpic,
  createPrizeEpic,
  getArticleEpic,
  getCourseEpic,
  getUserEpic,
  initClientEpic,
  listArticlesEpic,
  listCategoriesEpic,
  listCoursesEpic,
  listDrugsEpic,
  listPrizesEpic,
  listUserActionsEpic,
  listUsersEpic,
  loginEpic,
  logoutEpic,
  sendVerificationCodeEpic,
  updateArticleEpic,
  updateCourseEpic,
  updateUserActionForManagerEpic,
  updateUserEpic,
);

const rootReducer = combineReducers({
  banner,
  course: getCourseReducer,
  createArticle,
  createCourse,
  createCourseItem,
  createPrize,
  doctorsAndClinics,
  getArticle,
  getUser,
  listArticles,
  listCategories,
  listCourses,
  listDrugs,
  listPrizes,
  listUserActions,
  login,
  updateUser,
  updateUserActionForManager,
  users,
});

let store;

export function createStore() {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
