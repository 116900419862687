// Local dependencies
import {
  Contacts,
  EntryStatus,
  EntryType,
  Price,
  Service,
  User,
  UserStatus,
} from '@entities/users/lib/types';

export interface Drug {
  __typename: EntryType.DRUG;
  avatar: string;
  categoryId: string;
  company?: Company;
  companyId: string;
  description_en: string;
  description_kg?: string;
  description_ky: string;
  description_ru: string;
  id: string;
  images?: string[];
  isContentReplacement?: boolean;
  joinDate?: string;
  likeState?: number;
  name_en: string;
  name_kg?: string;
  name_ky: string;
  name_ru: string;
  operator?: User;
  phone?: string;
  quizId?: string;
  slug?: string;
  status?: UserStatus;
  title_en: string;
  title_kg?: string;
  title_ky: string;
  title_ru: string;
  videoUrl?: string;
}

export interface CreateDrugInput {
  avatar: string;
  categoryId: string;
  companyId: string;
  description_en: string;
  description_ru: string;
  description_kg: string;
  description_ky: string;
  images?: [string];
  name_en: string;
  name_ru: string;
  name_kg: string;
  name_ky: string;
  quizId?: string;
  title_en: string;
  title_kg: string;
  title_ky: string;
  title_ru: string;
  videoUrl: string;
}

export interface ListDrugs {
  drugs: Drug[];
  total: number;
}

export enum ListDrugsStatusFilter {
  ALL = 'ALL',
  APPROVED = EntryStatus.APPROVED,
  IN_REVIEW = EntryStatus.IN_REVIEW,
  REJECTED = EntryStatus.REJECTED,
}

export interface Company {
  id: string;
  avatar?: string;
  consultationPrice?: Price;
  contacts?: Contacts;
  email?: string;
  phonesExtra?: [string];
  profileCompletionRate?: number;
  servicesDescription?: string;
  services?: [Service];
  specialities?: [string];
  status?: EntryStatus;
  url?: string;
  phone: string;
  joinDate: number;
  __typename: EntryType;
  name?: string;
  description?: string;
}

export interface CreateDrugInputFields {
  [key: string]: any;
}
