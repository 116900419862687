// Local dependencies
import { Article, ListArticlesFilter } from '@entities/articles/types';

export enum ListArticlesActionTypes {
  LIST_ARTICLES_REQUEST = 'LIST_ARTICLES_REQUEST',
  LIST_ARTICLES_SUCCESS = 'LIST_ARTICLES_SUCCESS',
  LIST_ARTICLES_ERROR = 'LIST_ARTICLES_ERROR',
  LIST_ARTICLES_RESET = 'LIST_ARTICLES_RESET',
  LIST_ARTICLES_CHANGE_CURRENT_FILTER = 'LIST_ARTICLES_CHANGE_CURRENT_FILTER',
  LIST_ARTICLES_CHANGE_SEARCH_STRING = 'LIST_ARTICLES_CHANGE_SEARCH_STRING',
}

export type ListArticlesAction =
  | ListArticlesRequest
  | ListArticlesSuccess
  | ListArticlesError
  | ListArticlesReset
  | ListArticlesChangeCurrentFilter
  | ListArticlesChangeSearchString;

export interface ListArticlesRequest {
  type: ListArticlesActionTypes.LIST_ARTICLES_REQUEST;
  searchString: string;
  currentPage?: number;
  // filter: ListArticlesFilter;
}

export interface ListArticlesSuccess {
  type: ListArticlesActionTypes.LIST_ARTICLES_SUCCESS;
  total: number;
  articles: Article[];
}

export interface ListArticlesError {
  type: ListArticlesActionTypes.LIST_ARTICLES_ERROR;
  error: Error;
}

export interface ListArticlesReset {
  type: ListArticlesActionTypes.LIST_ARTICLES_RESET;
}

export interface ListArticlesChangeCurrentFilter {
  type: ListArticlesActionTypes.LIST_ARTICLES_CHANGE_CURRENT_FILTER;
  filter: string;
}

export interface ListArticlesChangeSearchString {
  type: ListArticlesActionTypes.LIST_ARTICLES_CHANGE_SEARCH_STRING;
  searchString: string;
}

export const DEFAULT_LIST_ARTICLES_FILTER = {
  category: '',
};

export function listArticles(
  searchString: string = '',
  currentPage: number = 1,
  // filter: ListArticlesFilter = DEFAULT_LIST_ARTICLES_FILTER,
): ListArticlesRequest {
  return {
    type: ListArticlesActionTypes.LIST_ARTICLES_REQUEST,
    searchString,
    currentPage,
    // filter,
  };
}

export function listArticlesSucceeded(
  total: number,
  articles: Article[],
): ListArticlesSuccess {
  return {
    type: ListArticlesActionTypes.LIST_ARTICLES_SUCCESS,
    total,
    articles,
  };
}

export function listArticlesFailed(error: Error): ListArticlesError {
  return {
    type: ListArticlesActionTypes.LIST_ARTICLES_ERROR,
    error,
  };
}

export function listArticlesReset(): ListArticlesReset {
  return {
    type: ListArticlesActionTypes.LIST_ARTICLES_RESET,
  };
}

export function listArticlesChangeCurrentFilter(
  filter: string,
): ListArticlesChangeCurrentFilter {
  return {
    type: ListArticlesActionTypes.LIST_ARTICLES_CHANGE_CURRENT_FILTER,
    filter,
  };
}

export function listArticlesChangeSearchString(
  searchString: string,
): ListArticlesChangeSearchString {
  return {
    type: ListArticlesActionTypes.LIST_ARTICLES_CHANGE_SEARCH_STRING,
    searchString,
  };
}
