// External dependencies
import update from 'immutability-helper';

// Local dependencies
import isValidUrl from '@shared/utils/isValidUrl';
import {
  CategoryLengthRequiredException,
  DataLengthRequiredExpection,
  TitleLengthRequiredException,
  ValidFormatForLinkRequiredException,
} from '../../lib/exceptions';

export default function validateCreateArticleFields(state, value) {
  const inputName = Object.keys(value)[0];
  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  switch (inputName) {
    case 'category':
      if (value[inputName].length < 3) {
        change.categoryError = { $set: new CategoryLengthRequiredException() };
      } else {
        delete state['categoryError'];
      }

      return update(state, {
        ...change,
        article: {
          category: { $set: value[inputName] },
        },
        sendingArticle: {
          category: { $set: value[inputName] },
        },
      });

    case 'data':
      if (value[inputName].length < 100) {
        change.dataError = { $set: new DataLengthRequiredExpection() };
      } else {
        delete state['dataError'];
      }

      return update(state, {
        ...change,
        article: {
          contents: [
            {
              data: { $set: value[inputName] },
            },
          ],
        },
        sendingArticle: {
          contents: [
            {
              data: { $set: value[inputName] },
            },
          ],
        },
      });

    case 'imageUrl':
      return update(state, {
        ...change,
        article: {
          heroMedia: {
            imageUrl: { $set: value[inputName] },
          },
        },
        sendingArticle: {
          $set: update(state.sendingArticle, {
            heroMedia: {
              $set: update(state.sendingArticle?.heroMedia || {}, {
                imageUrl: { $set: value[inputName] },
              }),
            },
          }),
        },
      });

    case 'articleType':
      return update(state, {
        ...change,
        article: {
          articleType: { $set: value[inputName] },
        },
        sendingArticle: {
          articleType: { $set: value[inputName] },
        },
      });

    case 'fullVideoUrl':
      if (!isValidUrl(value[inputName])) {
        change.fullVideoUrlError = {
          $set: new ValidFormatForLinkRequiredException(),
        };
      } else {
        delete state['fullVideoUrlError'];
      }

      return update(state, {
        ...change,
        article: {
          heroMedia: {
            fullVideoUrl: { $set: value[inputName] },
          },
        },
        sendingArticle: {
          $set: update(state.sendingArticle, {
            heroMedia: {
              $set: update(state.sendingArticle?.heroMedia || {}, {
                fullVideoUrl: { $set: value[inputName] },
              }),
            },
          }),
        },
      });

    case 'synopsis':
      if (value[inputName].length < 10) {
        change.synopsisError = { $set: new TitleLengthRequiredException() };
      } else {
        delete state['synopsisError'];
      }

      return update(state, {
        ...change,
        article: {
          synopsis: { $set: value[inputName] },
        },
        sendingArticle: {
          synopsis: { $set: value[inputName] },
        },
      });

    case 'title':
      if (value[inputName].length < 10) {
        change.titleError = { $set: new TitleLengthRequiredException() };
      } else {
        delete state['titleError'];
      }

      return update(state, {
        ...change,
        article: {
          title: { $set: value[inputName] },
        },
        sendingArticle: {
          title: { $set: value[inputName] },
        },
      });

    default:
      return state;
  }
}
