// Loacl dependencies
import { EntryStatus, EntryType } from '../../lib/types';

export enum ListUsersActionTypes {
  LIST_USERS_ERROR = 'LIST_USERS_ERROR',
  LIST_USERS_REQUEST = 'LIST_USERS_REQUEST',
  LIST_USERS_RESET = 'LIST_USERS_RESET',
  LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS',
  USERS_SORT_BY_ORDER = 'USERS_SORT_BY_ORDER',
  LIST_USERS_CURRENT_FILTER_CHANGE = 'LIST_USERS_CURRENT_FILTER_CHANGE',
  LIST_USERS_CURRENT_FILTER_CHANGE_RESET = 'LIST_USERS_CURRENT_FILTER_CHANGE_RESET',
  LIST_USERS_SEARCH_STRING_CHANGE = 'LIST_USERS_SEARCH_STRING_CHANGE',
  LIST_USERS_ERROR_RESET = 'LIST_USERS_ERROR_RESET',
}

export interface ListUsersRequest {
  type: ListUsersActionTypes.LIST_USERS_REQUEST;
  currentPage?: number;
  filter: ListUsersFilter;
  searchString: string;
  sort: Sort;
  size?: number;
}

export interface ListUsersFilter {
  locale?: string;
  entryTypes?: EntryType[];
  status?: EntryStatus;
  onlyBuilt?: boolean;
}

export interface ListUsersCurrentFilterChange {
  type: ListUsersActionTypes.LIST_USERS_CURRENT_FILTER_CHANGE;
  filter: string;
}

export interface ListUsersSuccess {
  type: ListUsersActionTypes.LIST_USERS_SUCCESS;
  users: any;
  total: number;
}

export interface ListUsersError {
  type: ListUsersActionTypes.LIST_USERS_ERROR;
  error: Error;
}

export interface ListUsersReset {
  type: ListUsersActionTypes.LIST_USERS_RESET;
}

export interface ListUsersErrorReset {
  type: ListUsersActionTypes.LIST_USERS_ERROR_RESET;
}

export interface ListUsersSearchStringChange {
  type: ListUsersActionTypes.LIST_USERS_SEARCH_STRING_CHANGE;
  searchString: string;
}

export interface ListUsersCurrentFilterChangeReset {
  type: ListUsersActionTypes.LIST_USERS_CURRENT_FILTER_CHANGE_RESET;
}

export const DEFAULT_LIST_USERS_FILTER: ListUsersFilter = {
  entryTypes: [EntryType.PHYSICIAN],
};

export interface Sort {
  field: SortField;
  direction: SortDirection;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortField {
  JOIN_DATE = 'joinDate',
}

export const DEFAULT_SORT = {
  field: SortField.JOIN_DATE,
  direction: SortDirection.DESC,
};

export type ListUsersAction =
  | ListUsersRequest
  | ListUsersReset
  | ListUsersSuccess
  | ListUsersError
  | ListUsersCurrentFilterChange
  | ListUsersSearchStringChange
  | ListUsersCurrentFilterChangeReset
  | ListUsersErrorReset;

export function listUsers(
  searchString: string,
  filter: ListUsersFilter,
  currentPage: number = 0,
  sort = DEFAULT_SORT,
): ListUsersRequest {
  return {
    type: ListUsersActionTypes.LIST_USERS_REQUEST,
    searchString,
    currentPage,
    filter,
    sort,
  };
}

export function listUsersSucceeded(
  total: number,
  users: any,
): ListUsersSuccess {
  return {
    type: ListUsersActionTypes.LIST_USERS_SUCCESS,
    users,
    total,
  };
}

export function listUsersCurrentFilterChange(
  filter: string,
): ListUsersCurrentFilterChange {
  return {
    type: ListUsersActionTypes.LIST_USERS_CURRENT_FILTER_CHANGE,
    filter,
  };
}

export function listUsersFailed(error: Error): ListUsersError {
  return {
    type: ListUsersActionTypes.LIST_USERS_ERROR,
    error,
  };
}

export function listUsersReset(): ListUsersReset {
  return {
    type: ListUsersActionTypes.LIST_USERS_RESET,
  };
}

export function listUsersErrorReset(): ListUsersErrorReset {
  return {
    type: ListUsersActionTypes.LIST_USERS_ERROR_RESET,
  };
}

export function sortUsersByOrder(field: string) {
  return {
    type: ListUsersActionTypes.USERS_SORT_BY_ORDER,
    field,
  };
}

export function listUsersSearchStringChange(searchString: string) {
  return {
    type: ListUsersActionTypes.LIST_USERS_SEARCH_STRING_CHANGE,
    searchString,
  };
}

export function listUsersCurrentFilterChangeReset(): ListUsersCurrentFilterChangeReset {
  return {
    type: ListUsersActionTypes.LIST_USERS_CURRENT_FILTER_CHANGE_RESET,
  };
}
