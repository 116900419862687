// External dependencies
import { filter, debounceTime, switchMap } from 'rxjs/operators';

// Local dependencies
import {
  ListUserActions,
  ListUserActionsActionTypes,
  listUserActionsError,
  listUserActionsSucceeded,
  ListUserActionsSucceeded,
  ListUserActionsTypes,
} from './actions';
import { listUserActionsQuery } from './queries';
import { getClient } from '@app/clients/medcheck';

export default function listUserActionsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListUserActionsTypes) =>
        action.type === ListUserActionsActionTypes.LIST_USER_ACTIONS_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListUserActions) =>
      listUserActions(action, state$?.value?.listUserActions?.size).catch(
        (error) => listUserActionsError(error),
      ),
    ),
  );
}

export async function listUserActions(
  { currentPage, searchString, filter }: ListUserActions,
  size: number,
): Promise<ListUserActionsSucceeded> {
  const graphQLClient = await getClient();

  const {
    data: {
      listUserActions: { usersActions, total },
    },
  } = await graphQLClient.query({
    query: listUserActionsQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size: size * currentPage,
        filter,
      },
    },
  });

  return listUserActionsSucceeded(total, usersActions);
}
