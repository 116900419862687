// External dependencies
import gql from 'graphql-tag';

export const createArticleMutation = gql`
  mutation createArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      id
      articleType
      category
      contents {
        type
        data
      }
      heroMedia {
        imageUrl
        fullVideoUrl
      }
      synopsis
      title
    }
  }
`;
