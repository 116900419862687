// External dependencies
import { gql as gqlApollo } from '@apollo/client';
import gql from 'graphql-tag';

export const listUsersQuery = gql`
  query listUsers($input: ListUsersInput) {
    listUsers(input: $input) {
      total
      users {
        typename: __typename
        ... on Clinic {
          id
          clinicName
          avatar
          status
          joinDate
          clinicAddress {
            address
          }
          slug
        }
        ... on Physician {
          id
          avatar
          status
          firstName
          lastName
          joinDate
          patronymic
          workplaces {
            clinicName
          }
          specialities
          slug
        }
        ... on Pharmacist {
          id
          avatar
          status
          firstName
          lastName
          joinDate
          slug
        }
      }
    }
  }
`;

export const listUsersWithApolloClient = gqlApollo`
  query listUsers($input: ListUsersInput) {
    listUsers(input: $input) {
      total
      users {
        typename: __typename
        ... on Clinic {
          id
          clinicName
          avatar
          status
          joinDate
          clinicAddress {
            address
          }
          slug
        }
        ... on Physician {
          id
          avatar
          status
          firstName
          lastName
          joinDate
          patronymic
          workplaces {
            clinicName
          }
          specialities
          slug
        }
        ... on Pharmacist {
          id
          avatar
          status
          firstName
          lastName
          joinDate
          slug
        }
      }
    }
  }
`;
