// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { RequiredFieldException } from '@shared/utils/exceptions';
import { CreatePrizeState } from './reducer';

export default function validateCreatePrizeFields(
  state: CreatePrizeState,
  prize,
) {
  const { amount, name, avatar, requiredPoints, orderNumber } = prize;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (prize.hasOwnProperty('amount')) {
    if (!amount) {
      change.amountError = {
        $set: new RequiredFieldException(),
      };
    } else {
      change['$unset'] = ['amountError'];
    }

    return update(state, {
      ...change,
      prize: {
        $set: update(state.prize, {
          amount: { $set: amount },
        }),
      },
    });
  }

  if (prize.hasOwnProperty('name')) {
    if (!name) {
      change.nameError = {
        $set: new RequiredFieldException(),
      };
    } else {
      change['$unset'] = ['nameError'];
    }

    return update(state, {
      ...change,
      prize: {
        $set: update(state.prize, {
          name: { $set: name },
        }),
      },
    });
  }

  if (prize.hasOwnProperty('avatar')) {
    if (!avatar) {
      change.avatarError = {
        $set: new RequiredFieldException(),
      };
    } else {
      change['$unset'] = ['avatarError'];
    }

    return update(state, {
      ...change,
      prize: {
        $set: update(state.prize, {
          avatar: {
            $set: update(state.prize.avatar, {
              [0]: {
                url: { $set: avatar },
              },
            }),
          },
        }),
      },
    });
  }

  if (prize.hasOwnProperty('requiredPoints')) {
    if (!requiredPoints) {
      change.requiredPointsError = {
        $set: new RequiredFieldException(),
      };
    } else {
      change['$unset'] = ['requiredPointsError'];
    }

    return update(state, {
      ...change,
      prize: {
        $set: update(state.prize, {
          requiredPoints: { $set: requiredPoints },
        }),
      },
    });
  }

  if (prize.hasOwnProperty('orderNumber')) {
    if (!orderNumber) {
      change.orderNumberError = {
        $set: new RequiredFieldException(),
      };
    } else {
      change['$unset'] = ['orderNumberError'];
    }

    return update(state, {
      ...change,
      prize: {
        $set: update(state.prize, {
          orderNumber: { $set: orderNumber },
        }),
      },
    });
  }

  return state;
}
