// Local dependencies
import { UserActionStatus } from '@entities/user-actions/types';
import { EntryStatus, EntryType, Service } from '@entities/users/lib/types';

export interface LocalizedStringMapInput {
  en: string;
  ru: string;
  ky: string;
}

interface VideoInput {
  previewImage: string;
  url: string;
  title?: string;
  description?: string;
  duration?: number;
}

export interface LocalizedVideoMapInput {
  en: VideoInput;
  ru: VideoInput;
  ky: VideoInput;
}

interface PriceInput {
  isFrom?: boolean;
  value: string;
  currency: string;
}

export interface CreateCourseInput {
  image?: LocalizedStringMapInput;
  name?: LocalizedStringMapInput;
  physicianId?: string;
  parentId: string;
  video?: LocalizedVideoMapInput;
  description?: LocalizedStringMapInput;
  title?: LocalizedStringMapInput;
  coursePrice?: PriceInput;
  ageRestrictions?: string;
}

interface LocalizedStringMap {
  en: string;
  ru: string;
  ky: string;
}

export interface LocalizedUrlMap {
  en: string;
  ru: string;
  ky: string;
}

interface Video {
  previewImage: string;
  url: string;
  title?: string;
  description?: string;
  duration?: number;
}

export interface LocalizedVideoMap {
  en: Video;
  ru: Video;
  ky: Video;
}

interface LocalizedVideo {
  previewImage: LocalizedUrlMap;
  url: string;
  title?: LocalizedStringMap;
  description?: LocalizedStringMap;
  duration?: number;
}

interface Price {
  isFrom?: boolean;
  value: string;
  currency: string;
}

interface WorkPhone {
  value: string;
}

interface SocialNetwork {
  value: string;
}

interface Email {
  value: string;
}

interface Contacts {
  workPhone: WorkPhone[];
  whatsApp: SocialNetwork;
  telegram: SocialNetwork;
  email: Email;
  instagram: SocialNetwork;
  facebook: SocialNetwork;
  website: SocialNetwork;
}

interface Entry {
  id: string;
  avatar?: string;
  consultationPrice?: Price;
  contacts?: Contacts;
  email?: string;
  phonesExtra?: [String];
  profileCompletionRate?: number;
  servicesDescription?: string;
  services?: [Service];
  specialities?: string[];
  status?: EntryStatus;
  url?: string;
  phone: string;
  joinDate: number;
  __typename: EntryType;
}

enum LikeState {
  LIKED = 'LIKED',
  DISLIKED = 'DISLIKED',
  UNSET = 'UNSET',
}

interface Review {
  id: string;
  parentId: string;
  author: Entry;
  message?: string;
  rating?: number;
  likeCount?: number;
  likeState?: LikeState;
  repliesCount?: number;
  postDate: number;
  updatedDate?: number;
}

export enum CourseStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export interface CourseItem {
  id: string;
  currentWatchTime?: number;
  description?: LocalizedStringMap;
  isLiked?: Boolean;
  isWatched?: Boolean;
  item?: LocalizedVideo;
  likesCount?: number;
  name?: LocalizedStringMap;
  parentId: string;
  postDate?: number;
  reviews?: [Review];
  reviewsCount?: number;
  status?: CourseStatus;
  title?: LocalizedStringMap;
  __typename: EntryType;
}

export interface Course {
  id: string;
  ageRestrictions?: string;
  courseItems?: CourseItem[];
  courseItemsCount?: number;
  coursePrice?: Price;
  description?: LocalizedStringMap;
  image?: LocalizedUrlMap;
  isLiked?: Boolean;
  purchaseStatus?: UserActionStatus;
  lastReview?: Review;
  likesCount?: number;
  name?: LocalizedStringMap;
  parentId?: string;
  physicianId?: string;
  postDate: number;
  reviews?: [Review];
  reviewsCount?: number;
  status?: CourseStatus;
  title?: LocalizedStringMap;
  totalDuration?: number;
  video?: LocalizedVideoMap;
  __typename: EntryType;
}

export interface CreateCourseItemInput {
  item?: LocalizedVideoInput;
  title?: LocalizedStringMapInput;
  name?: LocalizedStringMapInput;
  description?: LocalizedStringMapInput;
  parentId: string;
}

export interface CreateCourseItemInputFields {
  [key: string]: any;
}

export interface LocalizedVideoInput {
  previewImage: LocalizedUrlMapInput;
  url: string;
  title?: LocalizedStringMapInput;
  description?: LocalizedStringMapInput;
  duration?: number;
}

export interface LocalizedUrlMapInput {
  en?: string;
  ru?: string;
  ky?: string;
}

export interface ListCoursesInput {
  from: number;
  query: string;
  filter: ListCoursesFilter;
  size: number;
  locale: string;
}

export interface ListCoursesFilter {
  status: CourseStatus;
}

export interface UpdateCourseInput {
  ageRestrictions?: string;
  coursePrice?: PriceInput;
  description?: LocalizedStringMapInput;
  id: string;
  image?: LocalizedUrlMapInput;
  name?: LocalizedStringMapInput;
  title?: LocalizedStringMapInput;
  video?: LocalizedVideoMapInput;
  parentId: string;
  physicianId?: string;
}

export enum VideoImageUploaderType {
  VIDEO = 'video',
  IMAGE = 'image',
}
