// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '@app/clients/medcheck';
import { EntryType } from '../../lib/types';
import {
  GetUserAction,
  GetUserActionTypes,
  GetUserRequest,
  getUserFailure,
  getUserSucceeded,
} from './actions';
import {
  getPharmacistQuery,
  getUserQuery,
  getUserQueryClinic,
} from './queries';

export default function getUserEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetUserAction) =>
        action.type === GetUserActionTypes.GET_USER_REQUEST,
    ),
    switchMap((action: GetUserRequest) =>
      getUser(action)
        .then(getUserSucceeded)
        .catch((error: Error) => getUserFailure(error)),
    ),
  );
}

export async function getUser({
  id,
  __typename,
}: GetUserRequest): Promise<ProAccountEntry> {
  const graphQLClient = await getClient();
  let query;

  switch (__typename) {
    case EntryType.PHYSICIAN:
      query = getUserQuery;
      break;

    case EntryType.CLINIC:
      query = getUserQueryClinic;
      break;

    case EntryType.PHARMACIST:
      query = getPharmacistQuery;
      break;
  }

  const {
    data: { getUser },
  } = await graphQLClient.query({
    query: query,
    variables: {
      input: {
        id,
      },
    },
  });

  return getUser as ProAccountEntry;
}
