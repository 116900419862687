// External dependencies
import gql from 'graphql-tag';

export const createCourseMutation = gql`
  mutation createCourse($input: CreateCourseInput!) {
    createCourse(input: $input) {
      id
      ageRestrictions
      courseItems
      courseItemsCount
      coursePrice
      description
      image
      isLiked
      purchaseStatus
      lastReview
      likesCount
      name
      parentId
      physicianId
      postDate
      reviews
      reviewsCount
      status
      title
      totalDuration
      video
      __typename
    }
  }
`;
