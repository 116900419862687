// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DEFAULT_PAGE_SIZE } from '@shared/utils/helpers';
import { EntryType } from '../../lib/types';
import {
  DEFAULT_LIST_USERS_FILTER,
  DEFAULT_SORT,
  ListUsersAction,
  ListUsersActionTypes,
  ListUsersFilter,
  Sort,
} from './actions';

export interface ListUsersState {
  currentPage: number;
  error?: Error;
  filter?: ListUsersFilter;
  currentFilter?: string;
  from?: number;
  loading: boolean;
  searchString: string;
  size: number;
  total: number;
  sort: Sort;
  users?: any;
}

export const initialUsersState: ListUsersState = {
  currentPage: 1,
  filter: DEFAULT_LIST_USERS_FILTER,
  currentFilter: EntryType.PHYSICIAN,
  loading: false,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
  total: 0,
  users: [],
  sort: DEFAULT_SORT,
};

export default function listUsersReducer(
  state = initialUsersState,
  action: ListUsersAction,
) {
  switch (action.type) {
    case ListUsersActionTypes.LIST_USERS_REQUEST:
      const { searchString, filter, currentPage } = action;
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
        sort: { $set: action.sort },
        users: { $set: null },
        total: { $set: 0 },
      });

    case ListUsersActionTypes.LIST_USERS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        users: { $set: action.users },
        total: { $set: action.total },
      });

    case ListUsersActionTypes.LIST_USERS_CURRENT_FILTER_CHANGE:
      return update(state, {
        currentFilter: { $set: action.filter },
      });

    case ListUsersActionTypes.LIST_USERS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListUsersActionTypes.LIST_USERS_RESET:
      return update(initialUsersState, {
        loading: { $set: false },
      });

    case ListUsersActionTypes.LIST_USERS_ERROR_RESET:
      return update(initialUsersState, {
        loading: { $set: false },
        $unset: ['error'],
      });

    case ListUsersActionTypes.LIST_USERS_SEARCH_STRING_CHANGE:
      return update(initialUsersState, {
        searchString: { $set: action.searchString },
      });

    case ListUsersActionTypes.LIST_USERS_CURRENT_FILTER_CHANGE_RESET:
      return update(state, {
        total: { $set: 0 },
        users: { $set: [] },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
