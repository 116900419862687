// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DEFAULT_PAGE_SIZE } from '@shared/utils/helpers';
import { Course, ListCoursesFilter } from '../../types';
import {
  DEFAULT_LIST_COURSES_FILTER,
  ListCoursesAction,
  ListCoursesActionTypes,
} from './actions';

export interface ListCoursesState {
  courses: Course[];
  currentPage: number;
  filter: ListCoursesFilter;
  from?: number;
  error?: Error;
  loading: boolean;
  searchString: string;
  size: number;
  total: number;
}

export const ListCoursesInititalState: ListCoursesState = {
  courses: [],
  currentPage: 1,
  filter: DEFAULT_LIST_COURSES_FILTER,
  loading: false,
  searchString: '',
  size: DEFAULT_PAGE_SIZE,
  total: 0,
};

export default function listCoursesReducer(
  state = ListCoursesInititalState,
  action: ListCoursesAction,
) {
  switch (action.type) {
    case ListCoursesActionTypes.LIST_COURSES_REQUEST:
      const { searchString, filter } = action;

      return update(state, {
        loading: { $set: true },
        searchString: { $set: searchString },
        filter: { $set: filter },
      });

    case ListCoursesActionTypes.LIST_COURSES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        courses: { $set: action.courses },
        total: { $set: action.total },
      });

    case ListCoursesActionTypes.LIST_COURSES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListCoursesActionTypes.LIST_COURSES_CHANGE_CURRENT_FILTER:
      return update(state, {
        filter: {
          $set: update(state.filter, {
            status: { $set: action.filter },
          }),
        },
      });

    case ListCoursesActionTypes.LIST_COURSES_CHANGE_SEARCH_STRING:
      return update(state, {
        searchString: { $set: action.searchString },
      });

    case ListCoursesActionTypes.LIST_COURSES_RESET:
      return ListCoursesInititalState;

    default:
      return state;
  }
}
