// External depedencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '@app/clients/medcheck';
import {
  ListPrizesAction,
  ListPrizesActionTypes,
  ListPrizesSuccess,
  listPrizesFailed,
  listPrizesSucceeded,
  ListPrizesRequest,
} from './actions';
import { listPrizesQuery } from './queries';

export default function listPrizesEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListPrizesAction) =>
        action.type === ListPrizesActionTypes.LIST_PRIZES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListPrizesRequest) =>
      listPrizes(action, state$?.value?.listPrizes?.size).catch((error) =>
        listPrizesFailed(error),
      ),
    ),
  );
}

export async function listPrizes(
  { searchString, currentPage }: ListPrizesRequest,
  size: number,
): Promise<ListPrizesSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listPrizes: { prizes, total },
    },
  } = await graphQLClient.query({
    query: listPrizesQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        size: size,
        // query: searchString, //todo uncomment, after adding to the infra scheme
      },
    },
  });

  return listPrizesSucceeded(total, prizes);
}
